import React, { Component } from 'react'
import { Col, Grid, Row, Table } from 'react-bootstrap'
import * as moment from 'moment'
import 'moment/locale/pl'
import NotificationSystem from 'react-notification-system'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Card } from '../../components/Card/Card'
import { style } from '../../variables/Variables'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import '../style.css'
import { toHours, toSeconds, toShortHours } from './timeFunctions'
import './TimeReportEdit.scss'
import ToggleSwitch from '../Settlements/Components/ToggleSwitch'
import TimeReportOvertime from './TimeReportOvertime'

const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '..'

const systemParams = {
  'bitrix-calendar': { niceName: 'Calendar', className: 'bg-info' },
  'bitrix-task': { niceName: 'Bitrix', className: 'bg-success' },
  jira: { niceName: 'Jira', className: 'bg-warning' },
  manual: { niceName: 'Manual', className: 'bg-secondary-2' }
}

const placeholders = {
  'Brak zadań': 'czas, w którym nie miałeś przydzielonych zadań',
  Edukacja: 'np. prasówka, nowości, zdobywanie wiedzy, szkolenie innych',
  Marketing: 'np. akcje marketingowe, case studies, konkursy branżowe, nowe produkty, usługi i szablony ofert',
  Zatrudnienie: 'np. rekrutacje, umowy, aneksy i  oświadczenia pracownicze, ewaluacje i inne dotyczące zatrudnienia',
  'Inne CG': 'inne, niesklasyfikowane wyżej na rzecz CG, np. integracje, ankiety wew., nowe wytyczne i procedury',
  'Wielu klientów': 'czynności wykonywane HURTOWO dla różnych klientów, np. rozliczenia, estymacje, sprawdzanie statystyk'
}

class TimeReportEdit extends Component {
  constructor (props) {
    super(props)
    this.onResponse = props.onResponse
    this.setBtxCompanies = props.setBtxCompanies
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeDay = this.handleChangeDay.bind(this)
    this.changeTimemanInfo = this.changeTimemanInfo.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.confirmDay = this.confirmDay.bind(this)
    this.bitrixLogout = this.bitrixLogout.bind(this)
    this.updateTimeman = this.updateTimeman.bind(this)
    this.resetMode = this.resetMode.bind(this)
    this.superReset = this.superReset.bind(this)
    this.state = {
      confirmActive: false,
      loader: true,
      day: props.match.params.date || this.props.tasks.params.dayToView || moment().format('YYYY-MM-DD'),
      enabledNextButton: false,
      nonEmpty: false,
      timeman: {},
      btxLogOut: false,
      fullPageLoader: false
    }
  }

  componentDidMount () {
    this.showNotification('info', 'Pobieranie czasu pracy i zadań')
    Promise.all([
      fetch(`${API_SERVER}/db/api/btx_companies`).then(res => res.json()),
      this.setTimemanFromBtx(),
      this.getSpecialTimereportUserSettings(`${API_SERVER}/api/timeman/no-limit-overtime`, 'allowNoLimitOvertime'),
      this.getSpecialTimereportUserSettings(`${API_SERVER}/api/timeman/can-missing-timereport`, 'canMissingTimereport')
    ])
      .then(([btxCompanies]) => {
        this.setBtxCompanies(btxCompanies)
        this.loadNext()
      })
      .catch(error => console.error('Error:', error))
  }

  async getSpecialTimereportUserSettings (apiUrl, stateKeyProp) {
    try {
      const { user: { ID: userId } } = this.props
      const { idToView } = this.props.tasks.params || {}
      const result = await fetch(apiUrl)
      const apiCall = await result.json()
      const searchedUserId = idToView || userId
      const apiResIds = apiCall
        .split(',')
        .map(el => el.trim())
        .includes(searchedUserId.toString())
      this.setState({ [stateKeyProp]: apiResIds })
    } catch (e) {
      console.error(e)
    }
  }

  setTimemanFromBtx () {
    return new Promise((resolve, reject) => {
      if (this.state.day === moment().format('YYYY-MM-DD') && (!this.state.timeman.duration || this.state.timeman.duration === '')) {
        const { idToView } = this.props.tasks.params || {}
        const { ID } = this.props.user || {}
        fetch(`${API_SERVER}/api/user/set-timeman-from-btx`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: idToView || ID
          })
        })
          .then(res => res.json())
          .then(response => {
            if (response.error) {
              reject(response.error)
            } else {
              resolve()
            }
          })
          .catch(error => {
            reject(error)
          })
      } else {
        resolve()
      }
    })
  }

  loadNext () {
    const viewedMonth = moment(this.state.day).format('YYYY-MM')
    const previousMonth = moment().subtract(1, 'months').format('YYYY-MM')
    const moreThanTwoMonths = viewedMonth < previousMonth

    this.setState({ loader: true, data: [], timeman: {}, moreThanTwoMonths, info: '' })
    const { emailToView, idToView } = this.props.tasks.params || {}

    this.setState({ confirmActive: false }, () => {
      fetch(`${API_SERVER}/api/tasks/get`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          date: this.state.day,
          email: emailToView || this.props.user.EMAIL,
          id: idToView || this.props.user.ID
        })
      })
        .then(res => res.json())
        .then(response => {
          if (response.tasks) {
            response.tasks.forEach(el => {
              if (el.system === 'manual') {
                el.name = el.name.replace(el.category + '|', '')
              }
              el.time = toHours(el.time || 0)
              el.origTime = el.time
              el.origName = el.name
            })
            const logComment = response.timeman &&
                !response.timeman.confirmed &&
                response.timeman.log &&
                response.timeman.log.find(el => el.comment)
            const obj = {
              confirmActive: this.canConfirm(),
              loader: false,
              data: response.tasks,
              timeman: response.timeman || { duration: '', info: '' },
              btxLogOut: true,
              user: response.user,
              info: response.info,
              allowReset: response.allowReset,
              wasConfirmed: (!!response.timeman && !!response.timeman.confirmed) || !!logComment
            }
            if ((response.timeman || {}).date) {
              obj.day = response.timeman.date
            }
            if (response.timeman) {
              obj.timeman.info = response.timeman.info || ''
              obj.timeman.duration = response.timeman.duration ? toShortHours(response.timeman.duration) : ''
              obj.timeman.start = response.timeman.start ? moment(response.timeman.start).format('HH:mm') : ''
              obj.timeman.finish = response.timeman.finish ? moment(response.timeman.finish).format('HH:mm') : ''
              obj.btxLogOut = !response.timeman.duration
              obj.timeman.totalTime = response.timeman.totalTime || obj.timeman.duration
              obj.timeman.scStart = response.timeman.scStart || obj.timeman.start
              obj.timeman.prevDayOverTime = response.timeman.prevDayOverTime || response.user.overTime || '0:00'
              if (response.timeman.reset) {
                obj.wasConfirmed = false
                obj.timeman.workTime = null
              }
            } else if (response.user) {
              obj.timeman.prevDayOverTime = response.user.overTime || '0:00'
            }
            this.setState(obj)
            this.showNotification('success', 'Pobieranie danych zakończone')
          } else {
            this.showNotification('error', response.error ? response.error : 'Wystąpił błąd', 0)
            console.error('Error:', response)
            this.setState({ loader: false })
          }
        })
        .catch(error => {
          this.showNotification('error', 'Wystąpił błąd', 0)
          console.error('Error:', error)
        })
      this.enableNextButton()
    })
  }

  handleChangeDay (event) {
    const { id } = event.target
    const { day } = this.state
    const changeValue = id === 'prevDay' ? -1 : 1

    this.setState({
      day: moment(day).add(changeValue, 'days').format('YYYY-MM-DD')
    }, () => this.loadNext())
  }

  enableNextButton () {
    const { day } = this.state
    this.setState({ enabledNextButton: day <= moment().subtract(1, 'days').format('YYYY-MM-DD') })
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }
    this.refs.notificationSystem && this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  checkManualTasks () {
    const { data = [] } = this.state
    return data
      .filter(el => el.system === 'manual' && el.time !== '0:00')
      .every(el => el.name.trim() !== '')
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    let { name, value } = event.target
    const { idToView } = this.props.tasks.params || {}

    name = name.substr(4)
    const { data = [] } = this.state
    const dataFind = data.find(o => o.taskId === name && o.date === this.state.day)
    if (!dataFind) {
      this.showNotification('error', 'Wystąpił błąd', 0)
      return setTimeout(() => { window.location.reload(false) }, 1000)
    }
    const taskName = dataFind.name.trim()
    const origTime = toSeconds(dataFind.origTime) || 0
    const timeValue = value !== '' ? toSeconds(value) : 0

    if (timeValue !== origTime) {
      this.showNotification('info', 'Wysyłam dane...')

      const diffTime = timeValue - origTime
      const mongoId = dataFind._id
      const system = dataFind.system
      fetch(`${API_SERVER}/api/tasks/save`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          taskId: name,
          category: dataFind.category,
          name: taskName,
          mongoId,
          system,
          date: this.state.day,
          email: this.props.user.EMAIL,
          userId: idToView || this.props.user.ID,
          diffTime,
          time: timeValue,
          client: system === 'manual' ? (['Sprawdzanie statystyk', 'Wielu klientów'].includes(dataFind.category) ? '3446' : '1368') : ''
        })
      }).then(res => res.json())
        .catch((error) => {
          this.showNotification('error', 'Wystąpił błąd', 0)
          console.error('Error:', error)
        })
        .then((res) => {
          if (res && res.success) {
            this.showNotification('success', 'Dane zostały zapisane')
            const timeman = res.timeman
            const state = {}
            if (timeman) {
              if (timeman.duration) {
                timeman.duration = toShortHours(timeman.duration)
              }
              state.timeman = timeman
            }
            state.confirmActive = this.canConfirm()
            state.data = data.map(task => {
              if (task.taskId === name) {
                return { ...task, time: toHours(timeValue), origTime: toHours(timeValue) }
              } else {
                return task
              }
            })
            this.setState(state)
          } else {
            this.showNotification('error', 'Wystąpił błąd', 0)
            console.error('Error:', res)
            this.loadNext()
          }
        })
    } else {
      this.setState({
        confirmActive: true
      })
    }
  }

  handleSubmitName (event) {
    event.preventDefault()
    event.stopPropagation()

    let { name } = event.target
    const { idToView } = this.props.tasks.params || {}
    name = name.substr(4)
    const { data = [] } = this.state
    const dataFind = data.find(o => o.taskId === name && o.date === this.state.day)
    const taskName = dataFind.name.trim()
    const value = dataFind.time
    const timeValue = value !== '' ? toSeconds(value) : 0
    const origName = dataFind.origName

    if (taskName !== origName) {
      this.showNotification('info', 'Wysyłam dane...')
      const mongoId = dataFind._id
      const system = dataFind.system
      fetch(`${API_SERVER}/api/tasks/save`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          taskId: name,
          category: dataFind.category,
          name: taskName,
          mongoId,
          system,
          date: this.state.day,
          email: this.props.user.EMAIL,
          userId: idToView || this.props.user.ID,
          diffTime: 0,
          time: timeValue,
          client: system === 'manual' ? (['Sprawdzanie statystyk', 'Wielu klientów'].includes(dataFind.category) ? '3446' : '1368') : ''
        })
      }).then(res => res.json())
        .catch((error) => {
          this.showNotification('error', 'Wystąpił błąd', 0)
          console.error('Error:', error)

          this.setState({
            confirmActive: true
          })
        })
        .then((res) => {
          if (res && res.success) {
            this.showNotification('success', 'Dane zostały zapisane')
            this.setState({
              confirmActive: this.canConfirm(),
              data: data.map(task => {
                if (task.taskId === name) {
                  return { ...task, name: taskName }
                } else {
                  return task
                }
              })
            })
          } else {
            this.showNotification('error', 'Wystąpił błąd', 0)
            console.error('Error:', res)
            this.setState({
              confirmActive: true
            })
          }
        })
    } else {
      this.setState({
        confirmActive: this.canConfirm()
      })
    }
  }

  getCompanyName (data, arr = []) {
    const obj = arr.length ? arr.find(o => o.ID === data) : ''
    return obj ? obj[Object.keys(obj)[2]] : data
  };

  nonEmptySwitch () {
    const { nonEmpty } = this.state
    return (
      <ToggleSwitch
        checked={nonEmpty}
        style={{ marginTop: '0.75rem', marginLeft: '0.5rem' }}
        name='nonEmpty'
        label='niezerowe'
        toggleSwitch={() => this.setState({ nonEmpty: !nonEmpty })}
      />
    )
  }

  getLink (el) {
    const { system, taskId, name = '' } = el
    let manualCategory
    if (taskId.includes('MAN-')) {
      [, , manualCategory] = taskId.split('-')
    }

    const ID = this.props.user.ID
    const reverseDay = this.state.day.split('-').reverse().join('.')
    if (system === 'manual') {
      return (
        <textarea
          name={el ? 'name' + el.taskId : ''}
          value={name}
          className={`hoverInput textarea ${!!el.time && el.time !== '0:00' && el.name === '' ? 'input-error' : ''}`}
          placeholder={placeholders[manualCategory] || 'Wymagany komentarz po ręcznym dodaniu czasu'}
          style={{
            width: '90%',
            height: '3.2rem',
            padding: '5px',
            borderRadius: '4px',
            textAlign: 'left',
            resize: 'auto'
          }}
          onFocus={e => {
            this.setState({
              confirmActive: false
            })
          }}
          onBlur={e => {
            this.handleSubmitName(e)
          }}
          onChange={(e) => {
            const value = e.target.value
            this.setState((data) => {
              el.name = value
              return data
            })
          }}
        />
      )
    }
    return (
      <div><a
        href={
          system === 'bitrix-task'
            ? `https://cube.bitrix24.pl/company/personal/user/${ID}/tasks/task/view/${taskId}/`
            : system === 'bitrix-calendar'
              ? `https://cube.bitrix24.pl/company/personal/user/${ID}/calendar/?EVENT_ID=${taskId}&EVENT_DATE=${reverseDay}/`
              : system === 'jira' ? `https://cubegroup.atlassian.net/browse/${taskId}` : 'http://#'
        }
        className='link'
        target='_blank'
        rel='noopener noreferrer'
           >{name}
      </a> <sup>{taskId}</sup>
      </div>
    )
  }

  confirmDay () {
    if (this.state.confirmActive) {
      if (this.canConfirmDayTime()) {
        if (this.canConfirmTasksTime(this.calculateSum())) {
          const { idToView } = this.props.tasks.params || {}
          let url = `${API_SERVER}/db/api/timemans`
          let method = 'POST'
          let body = {}
          if (this.state.timeman.id) {
            url += '/' + this.state.timeman.id
            method = 'PATCH'
          }
          this.setState({
            confirmActive: false
          }, () => {
            this.showNotification('info', 'Zapisywanie...')
            this.setState({
              fullPageLoader: true
            })
            body = {
              date: this.state.day,
              userId: idToView || this.props.user.ID,
              duration: toShortHours(this.state.timeman.duration),
              confirmed: true,
              confirmedBy: this.props.user.ID,
              confirmedAt: new Date(),
              tasksTime: this.calculateSum().time,
              reset: false
            }
            if (!this.state.wasConfirmed) {
              body = {
                ...body,
                info: (toSeconds(this.state.timeman.lackOfDayTime) || toSeconds(this.state.timeman.overTime)) ? this.state.timeman.info : '',
                scStart: this.state.timeman.scStart,
                scFinish: this.state.timeman.scFinish,
                baseTime: this.state.timeman.baseTime,
                workedOffTime: this.state.timeman.workedOffTime,
                overTime: this.state.timeman.overTime,
                totalTime: this.state.timeman.totalTime,
                usedOverTime: this.state.timeman.usedOverTime,
                lackOfDayTime: this.state.timeman.lackOfDayTime,
                prevDayOverTime: this.state.timeman.prevDayOverTime,
                nextDayOverTime: this.state.timeman.nextDayOverTime,
                workTime: this.state.timeman.workTime,
                freeHours: this.state.timeman.freeHours,
                freeHoursInfo: this.state.timeman.freeHoursInfo,
                correctedWorkTime: this.state.timeman.correctedWorkTime,
                overTimeAgree: (toSeconds(this.state.timeman.overTime) > 30 * 60) ? this.state.timeman.overTimeAgree : false
              }
            }
            fetch(url, {
              method,
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(body)
            })
              .then(res => res.json())
              .then(response => {
                this.setState({
                  fullPageLoader: false
                })
                if (response && response.error) {
                  this.showNotification('error', 'Wystąpił błąd', 0)
                } else {
                  this.showNotification('success', 'Zapisano dane')
                  this.loadNext()
                }
              })
              .catch((err) => console.error('NOT OK', err))
          })
        } else {
          this.setState({
            fullPageLoader: false
          })
          this.showNotification('error', `Łączna suma zaraportowanego czasu musi znaleźć się w granicach
          między 90% a 105% łącznego czasu pracy.
          Dokonaj stosownej korekty w zadaniach i wydarzeniach lub dodaj brakujący czas w dodatkowych polach manualnych (fioletowe)`, 20)
        }
      } else {
        this.showNotification('error',
          this.state.timeman.freeHours && !this.state.timeman.freeHoursInfo
            ? 'Uzupełnij komentarz do któtszego dnia pracy'
            : `Czas pracy powinien wynieść ${this.state.timeman.todayWorkTime}. W przeciwnym wypadku wpisz uzasadnienie w polu "komentarz do nadgodzin" lub "komentarz do niedoboru czasu".`, 10)
      }
    } else {
      this.showNotification('warning', 'Trwa inna operacja zapisywania danych, poczekaj na jej zakończenie i kliknij ponownie.', 10)
    }
  }

  changeTimemanInfo (event) {
    const { value } = event.target
    this.setState((state) => {
      state.timeman.info = value
      state.confirmActive = this.canConfirm()
      return state
    })
    event.preventDefault()
    event.stopPropagation()
  }

  handleChange (e) {
    e.preventDefault()
    e.stopPropagation()
    const { value, name } = e.target
    this.setState((state) => {
      state.timeman[name] = value
      return state
    })
  }

  // Liczy sumę czasów wszystkich taseczków
  calculateSum () {
    const { data = [] } = this.state
    return data && data.length
      ? data.reduce((a, b) => (
        { time: a.time + toSeconds(b.time || 0) }), { time: 0 })
      : { time: 0 }
  }

  canConfirm () {
    return this.canConfirmDayTime() && this.canConfirmTasksTime(this.calculateSum()) && this.checkManualTasks()
  }

  canConfirmDayTime () {
    const { timeman, allowNoLimitOvertime } = this.state
    const hasRequiredInfo = (timeman.lackOfDayTime || timeman.overTime) ? !!timeman.info : true
    const hasRequiredFreeHoursInfo = timeman.freeHours ? !!timeman.freeHoursInfo : true
    const hasScStart = !!timeman.scStart
    const hasNoMoreOvertimes = allowNoLimitOvertime ? true : toSeconds(timeman.prevDayOverTime) > 8 * 60 * 60 ? toSeconds(timeman.overTime) === 0 : true

    return moment(this.state.day).format('YYYY-MM-DD') < '2022-02-01' // data wdrożenia nowego rozliczania cz.pracy
      ? true
      : hasRequiredInfo &&
      hasRequiredFreeHoursInfo &&
      hasScStart &&
      hasNoMoreOvertimes
  }

  canConfirmTasksTime (dataSum) {
    const { canMissingTimereport } = this.state
    let seconds = toSeconds(this.state.timeman.totalTime)
    if (moment(this.state.day).format('YYYY-MM-DD') < '2022-02-01') {
      seconds = toSeconds(this.state.timeman.duration)
    }

    return (canMissingTimereport && dataSum.time <= Math.floor(seconds * 1.05)) || (dataSum.time >= Math.floor(seconds * 0.9) && dataSum.time <= Math.floor(seconds * 1.05))
  }

  diffTasksTime (dataSum) {
    const seconds = toSeconds(this.state.timeman.totalTime)

    if (dataSum.time < Math.floor(0.9 * seconds)) {
      return Math.floor((dataSum.time - 0.9 * seconds) / 60) * 60
    } else if (dataSum.time > Math.floor(1.05 * seconds)) {
      return Math.ceil((dataSum.time - 1.05 * seconds) / 60) * 60
    } else {
      return 0
    }
  }

  updateTimeman (timeman) {
    this.setState((state) => {
      state.timeman = timeman
      state.confirmActive = this.canConfirm()
      return state
    })
  }

  bitrixLogout () {
    this.setState({
      confirmActive: false
    }, () => {
      this.showNotification('info', 'Trwa pobieranie danych. Po wyświetleniu statystyk dokonaj ewentualnej korekty i zaakceptuj dzień przyciskiem "Zatwierdź"', 10)
      const { ID } = this.props.user || {}
      fetch(`${API_SERVER}/api/user/btxlogout`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: ID
        })
      })
        .then((response) => {
          if (response.error) {
            this.showNotification('error', 'Wystąpił błąd', 0)
            console.error('Error:', response.error)
          }
        })
        .then(() => this.setTimemanFromBtx())
        .then(() => this.loadNext())
        .catch(error => {
          this.showNotification('error', 'Wystąpił błąd', 0)
          console.error('Error:', error)
        })
    })
  }

  resetMode () {
    const { idToView } = this.props.tasks.params || {}
    fetch(`${API_SERVER}/db/api/timemans/${this.state.timeman.id}`,
      {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: idToView || this.props.user.ID,
          reset: true,
          confirmed: false,
          comment: 'Włączyłeś edycję swojego czasu pracy. Dokonaj korekty i ponownie zatwierdź dzień.'
        })
      })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          this.showNotification('error', 'Wystąpił błąd', 0)
        } else {
          this.loadNext()
        }
      })
      .catch(console.error)
  }

  superReset () {
    const { idToView } = this.props.tasks.params || {}
    this.setState({ loader: true })
    fetch(`${API_SERVER}/api/user/super-reset`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: idToView || this.props.user.ID,
          date: this.state.day
        })
      })
      .then(res => res.json())
      .then(response => {
        if (response.error) {
          this.showNotification('error', 'Wystąpił błąd', 0)
        } else {
          this.loadNext()
        }
      })
      .catch(console.error)
  }

  render () {
    let { data = [], loader, enabledNextButton, moreThanTwoMonths } = this.state
    const { companies = [] } = this.props.main
    const { params = [] } = this.props.tasks
    const { user = {} } = this.props
    const { day } = this.state

    if (this.state.nonEmpty) {
      data = data.filter(el => el.time !== '0:00')
    }

    const isTaskOwner = (!this.props.tasks.params.emailToView ||
      user.EMAIL === this.props.tasks.params.emailToView ||
      ['200', '298'].includes(user.ID))

    const isSupervisor = (user.scHead || []).includes(this.props.tasks.params.idToView) && !moreThanTwoMonths

    data.sort((a, b) => (a.system > b.system) ? 1 : ((b.system > a.system) ? -1 : 0))

    return (
      <div className='content timereport TimeReportEdit'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <form className='form-inline' autoComplete='off'>
          <Grid fluid>
            {user.googleAuth === true &&
              <div className='alert alert-danger'>Funkcjonalność dostępna tylko po zalogowaniu przez Bitrix</div>}
            <Row>
              <Col sm={12} lg={4}>
                <div className='d-flex justify-content-between align-items-center'>
                  <Link to='/timereport' className='btn btn-default btn-sm' role='button'>« wróć do Raportu</Link>
                  <div className='text-right w-100'>
                    <a className='header-link' target='_blank' rel='noopener noreferrer' href='https://cube.bitrix24.pl/company/personal/user/200/blog/3456/' style={{ fontSize: 14 }}>FAQ. Akceptacja dnia pracy</a>
                    &nbsp; &nbsp;
                    <a className='header-link' target='_blank' rel='noopener noreferrer' href='https://cube.bitrix24.pl/company/personal/user/200/blog/3452/' style={{ fontSize: 14 }}>Legenda widoku</a>
                  </div>
                </div>
                {loader ? <div className='lds-dual-ring' id='spinner' /> : ''}
                <Card
                  footer='d-none'
                  title={
                    <>
                      <div className='Title'>
                        Lista zadań <small><i>{params.userNameToView}</i></small><br /> z dnia {day}, {moment(day).format('dddd')}
                      </div>
                    </>
                  }
                  content={
                    <div className={loader ? 'noEvents' : ''}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <div>
                          <input
                            type='button'
                            className='btn btn-sm btn-secondary'
                            value='« poprzedni'
                            id='prevDay'
                            onClick={this.handleChangeDay}
                          />
                          <SingleDatePicker
                            date={moment(this.state.day)}
                            onDateChange={date => this.setState({ day: moment(date).format('YYYY-MM-DD') }, () => this.loadNext())}
                            focused={this.state.focused}
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            id='day'
                            showDefaultInputIcon
                            noBorder
                            isOutsideRange={(date) => date > moment()}
                            numberOfMonths={1}
                          />
                          <input
                            type='button'
                            className='btn btn-sm btn-secondary'
                            value='następny »'
                            id='nextDay'
                            onClick={this.handleChangeDay}
                            disabled={!enabledNextButton}
                          />
                        </div>
                      </div>
                      <br />
                      {
                        ['200', '360', '536', '168'].includes(this.props.user.ID) && this.state.timeman.confirmed &&
                          <input
                            type='button'
                            className='btn btn-sm btn-danger'
                            style={{
                              margin: 0,
                              padding: '0 6px'
                            }}
                            onClick={this.superReset}
                            value='SUPER RESET'
                          />
                      }
                    </div>
                  }
                />
              </Col>
              <Col sm={12} lg={8}>
                <Card
                  footer='d-none'
                  content={
                    <TimeReportOvertime
                      state={this.state}
                      changeTimemanInfo={this.changeTimemanInfo}
                      handleChange={this.handleChange}
                      confirmDay={this.confirmDay}
                      bitrixLogout={this.bitrixLogout}
                      userId={this.props.tasks.params.idToView || this.props.user.ID}
                      updateTimeman={this.updateTimeman}
                      resetMode={this.resetMode}
                    />
                  }
                />
              </Col>
            </Row>
            {
              !loader &&
              !this.state.info &&
              !this.state.timeman.confirmed &&
              !(this.state.timeman.duration || this.state.timeman.totalTime) &&
                <Row>
                  <Col md={12}>
                    <Card
                      footer='d-none'
                      style={{ border: '1px solid red' }}
                      content={
                        <span style={{ color: 'red' }}>{
                        this.state.day === moment().format('YYYY-MM-DD')
                          ? 'Zakończ dzień w bitrix.'
                          : 'Wpisz łączny czas pracy.'
                      }
                        </span>
                    }
                    />
                  </Col>
                </Row>
            }
            {
              !loader &&
              !this.state.info &&
              !this.state.timeman.confirmed &&
              this.state.timeman.comment &&
                <Row>
                  <Col md={12}>
                    <Card
                      footer='d-none'
                      style={{ border: '1px solid red' }}
                      content={
                        <span style={{ color: 'red' }}>{`${this.state.timeman.comment} ${!this.state.timeman.reset ? 'Zweryfikuj zaraportowany czas i dokonaj ponownej akceptacji dnia.' : ''}`}</span>
                    }
                    />
                  </Col>
                </Row>
            }
            {
              !loader &&
              !this.state.info &&
              !this.canConfirmTasksTime(this.calculateSum()) &&
              (this.state.timeman.duration || this.state.timeman.totalTime) &&
                <Row>
                  <Col md={12}>
                    <Card
                      footer='d-none'
                      style={{ border: '1px solid red' }}
                      content={
                        <span style={{ color: 'red' }}>{
                        this.state.timeman.totalTime === '0:00'
                          ? 'Zaraportowałeś łączny czas pracy 0:00, dlatego czas na zadaniach i wydarzeniach też powinien tyle wynieść. Dokonaj korekty.'
                          : 'Popraw czas zaraportowany w zadaniach, wydarzeniach i pozycjach manualnych. Łączna jego suma musi znaleźć się w granicach między 90% a 105% łącznego Twojej czasu pracy. Brakujący czas lub jego nadwyżka wyświetlona jest w podsumowaniu.'
                      }
                        </span>
                    }
                    />
                  </Col>
                </Row>
            }
            {
              !loader &&
              !this.state.info &&
              !this.state.timeman.confirmed &&
              !this.checkManualTasks() &&
                <Row>
                  <Col md={12}>
                    <Card
                      footer='d-none'
                      style={{ border: '1px solid red' }}
                      content={<span style={{ color: 'red' }}>Uzupełnij brakujące komentarze w pozycjach manualnych</span>}
                    />
                  </Col>
                </Row>
            }
            {
              !loader &&
              !this.state.info &&
              !this.state.timeman.confirmed &&
              (!this.state.timeman.scStart || this.state.timeman.scStart === '0:00') &&
              this.state.timeman.totalTime &&
                <Row>
                  <Col md={12}>
                    <Card
                      footer='d-none'
                      style={{ border: '1px solid red' }}
                      content={<span style={{ color: 'red' }}>Uzupełnij godzinę startu pracy</span>}
                    />
                  </Col>
                </Row>
            }
            {
              !loader &&
              !this.state.info &&
              !this.state.timeman.confirmed &&
              !this.state.allowNoLimitOvertime &&
              (toSeconds(this.state.timeman.prevDayOverTime) > 8 * 60 * 60 && toSeconds(this.state.timeman.overTime) !== 0) &&
                <Row>
                  <Col md={12}>
                    <Card
                      footer='d-none'
                      style={{ border: '1px solid red' }}
                      content={
                        <span style={{ color: 'red' }}>Twój poziom nadgodzin przekroczył już wymiar jednego dnia pracy. Nie możesz wypracować więcej ponadnormatywnego czasu  - Twój czas pracy nie może przekroczyć wymiaru dziennego do momentu odbioru nadgodzin.</span>
                    }
                    />
                  </Col>
                </Row>
            }
            <Row>
              <Col md={12}>
                <Card
                  content={
                    !loader && <Table hover>
                      <thead>
                        <tr>
                          <th style={{ width: '3%' }}>Lp</th>
                          <th style={{ width: '7%' }}>System</th>
                          <th style={{ width: '8%' }}>Kategoria</th>
                          <th style={{ width: '18%' }}>Klient</th>
                          <th style={{ width: '47%' }}>Zadanie <span style={{ marginLeft: '40%' }}>{this.nonEmptySwitch()}</span></th>
                          <th style={{ width: '7%' }}>Suma zadań</th>
                          <th style={{ width: '10%' }}><input
                            readOnly
                            className='hoverInput sum'
                            style={{ fontSize: '2rem' }}
                            value={toHours(this.calculateSum().time) || ''}
                                                       />
                          </th>
                        </tr>
                        {
                          !loader &&
                          (this.state.timeman.duration || this.state.timeman.totalTime) &&
                          !this.canConfirmTasksTime(this.calculateSum()) &&
                            <tr>
                              <th colSpan={5}>&nbsp;</th>
                              <th style={{ width: '10%', color: 'red' }}>{this.diffTasksTime(this.calculateSum()) < 0 ? 'brakuje' : 'za dużo'}</th>
                              <th style={{ width: '10%' }}><input
                                readOnly
                                className='hoverInput sum'
                                style={{ fontSize: '2rem', color: 'red' }}
                                value={toHours(this.diffTasksTime(this.calculateSum()))}
                                                           />
                              </th>
                            </tr>
                        }
                      </thead>
                      <tbody>
                        {
                          data && data.length > 0
                            ? data.map((el, key) => (
                              <tr key={key} className={systemParams[el.system].className}>
                                <td>{key + 1}</td>
                                <td>{el && el.system ? systemParams[el.system].niceName : '--'}</td>
                                <td>{el && el.category ? el.category : ''}</td>
                                <td>{el && el.client ? this.getCompanyName(el.client, companies) : ''}</td>
                                <td colSpan={2}>{this.getLink(el)}</td>
                                <td><input
                                  // wyłącza edycję jeśli taski nie należą do zalogowanej osoby lub jest przełożonym i zadania nie są btx lub jira
                                  readOnly={((isSupervisor && el.system !== 'manual') && !isTaskOwner) || this.state.timeman.confirmed}
                                  type='text'
                                  name={el ? 'time' + el.taskId : ''}
                                  placeholder='hh:mm'
                                  className='hoverInput'
                                  onChange={(e) => {
                                    const value = e.target.value
                                    this.setState((data) => {
                                      el.time = value
                                      return data
                                    })
                                  }}
                                  onFocus={e => {
                                    this.setState({
                                      confirmActive: false
                                    })
                                  }}
                                  onBlur={e => {
                                    this.handleSubmit(e)
                                  }}
                                  onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                      this.handleSubmit(e)
                                    }
                                  }}
                                  value={el.time !== '00:00' ? el.time : ''}
                                    />
                                </td>
                              </tr>
                            ))
                            : null
                        }
                      </tbody>
                      <thead>
                        <tr>
                          <th colSpan={5}>&nbsp;</th>
                          <th>Suma zadań</th>
                          <th><input
                            readOnly
                            className='hoverInput sum'
                            style={{ opacity: 0.8 }}
                            value={toHours(this.calculateSum().time) || ''}
                              />
                          </th>
                        </tr>
                      </thead>
                    </Table>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </form>
        <div id='own-preloader' className={`${this.state.fullPageLoader ? 'show' : ''}`}>
          <div id='own-loader' />
          <p className='preloader-text preloader-text-right preloader-text-extremely-visible'>Trwa zapisywanie...<br />POCZEKAJ na jego zakończenie</p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    tasks: state.tasks,
    user: state.user,
    main: state.main
  }
}

const mapDispatchToProps = {
  onRequest: id => ({
    type: 'REQUEST_TASKS'
  }),
  setBtxCompanies: companies => ({
    type: 'MAIN_COMPANIES',
    companies
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeReportEdit)
