const formInvoice = {
  _id: '',
  order_id: { pl: 'ID zlecenia', type: 'text', req: true },
  company: { pl: 'Zleceniodawca', type: 'select', source: 'companies' },
  client: { pl: 'Klient' },
  order_name: { pl: 'Kampania' },
  mainDepart: { pl: 'Dział główny' },
  mainPerson: { pl: 'Opiekun Klienta' },
  month: { pl: 'Data', type: 'text', req: true },
  sum: { pl: 'Suma' },
  status: { pl: 'Status', type: 'select', req: true },
  comment: { pl: 'Komentarz', type: 'textarea' }
}

const statusCode = [
  { ID: '0', NAME: 'oczekuje' },
  { ID: '1', NAME: 'do zatwierdzenia' },
  { ID: '2', NAME: 'do wystawienia' },
  { ID: '3', NAME: 'wystawiona' },
  { ID: '4', NAME: 'w trakcie korekty' },
  { ID: '5', NAME: 'korekta do zatwierdzenia' }
]

module.exports = {
  formInvoice,
  statusCode
}
