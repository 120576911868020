import {
  FETCH_EMPLOYEES_LOCATIONS_REQUEST,
  FETCH_EMPLOYEES_LOCATIONS_SUCCESS,
  FETCH_EMPLOYEES_LOCATIONS_FAILURE
} from '../actions/employeesLocations'

const initialState = {
  data: null,
  loading: false,
  error: null
}

const employeesLocationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEES_LOCATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case FETCH_EMPLOYEES_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      }
    case FETCH_EMPLOYEES_LOCATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export default employeesLocationsReducer
