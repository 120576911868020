import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import * as moment from 'moment'
import { toHours, toSeconds, toShortHours } from './timeFunctions'
import ToggleSwitch from '../Settlements/Components/ToggleSwitch'
import Tooltip from '../../components/Tooltip/Tooltip'
import tooltips from './tooltips'
import fetchApi, { API_SERVER } from '../../fetchApi'
import _ from 'lodash'

const ReadOnlyinput = ({ value, color = 'unset' }) => <input
  readOnly
  className='border-input'
  style={{ fontSize: '2rem', textAlign: 'right', borderColor: 'transparent', color }}
  value={value || ''}
                                                      />

const TimeReportOvertime = ({
  state,
  changeTimemanInfo,
  handleChange,
  confirmDay,
  bitrixLogout,
  updateTimeman,
  resetMode
}) => {
  const { timeman = {}, user = {}, info, wasConfirmed, day, loader, btxLogOut, allowReset } = _.cloneDeep(state)
  const { ID: userId } = user
  // obsluga przy zmianie dnia,
  // sprawdza czy to piatek,
  // wlacza/wylacza switch 'krotszy dzien pracy'
  // wlacza/wylacza edycję pol
  const [allowEdit, setAllowEdit] = useState(false)
  const isFriday = () => (moment() >= moment('2021-10-06') && (moment(day).day() === 5))

  useEffect(() => {
    try {
      // wymiar czasu pracy
      userId && (async () => {
        if (!timeman.workTime) {
          const userWorktime = await fetchApi(`${API_SERVER}/api/user/get-user-worktime`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              userId,
              day
            })
          })
          if (userWorktime.error) throw new Error(userWorktime.error)
          timeman.workTime = timeman.workTime ? timeman.workTime : toHours(+userWorktime * 60 * 60)
        }
        setShorterDaySwitch(false)
        setShowTotalTimeComment(false)
        // piątek 1h wolne
        if (!timeman.freeHours && isFriday() && !loader && !wasConfirmed) {
          timeman.freeHoursInfo = 'krótszy czas pracy w piątek'
          timeman.freeHours = toHours(toSeconds(timeman.workTime) / 8)
        }
        // gdy byly juz zapisane wolne godziny
        if (timeman.freeHours) {
          setShorterDaySwitch(true)
        }
        correctWorkTime()
        setWasEdited(false)
        if (!loader && !wasConfirmed) {
          if (!timeman.confirmed && !(day === moment()
            .format('YYYY-MM-DD') && btxLogOut)) {
            setAllowEdit(true)
          } else {
            setAllowEdit(false)
          }
        } else {
          setAllowEdit(false)
        }
        updateTimeman(timeman)
      })()
    } catch (err) {
      console.error(err.message || err)
    }
  }, [day, loader, wasConfirmed, userId])

  // krotszy dzien pracy
  const [shorterDaySwitch, setShorterDaySwitch] = useState(false)
  const toggleShorterDaySwitch = () => {
    if (!loader) {
      setShorterDaySwitch(!shorterDaySwitch)
    }
  }

  // zeruje wpisy o krotszym dniu pracy gdy switch jest wyłaczony
  useEffect(() => {
    if (!shorterDaySwitch) {
      timeman.freeHours = ''
      timeman.freeHoursInfo = ''

      correctWorkTime()
      updateTimeman(timeman)
    }
  }, [shorterDaySwitch])

  // koryguje dzien pracy z powodu wolnych godzin
  const correctWorkTime = () => {
    timeman.correctedWorkTime = timeman.freeHours
      ? toHours(toSeconds(timeman.workTime) - toSeconds(timeman.freeHours))
      : ''
  }
  timeman.todayWorkTime = timeman.correctedWorkTime || timeman.workTime

  // marginesy
  const [wasEdited, setWasEdited] = useState(false)
  const minMargin = toSeconds('-0:05')
  const maxMargin = toSeconds('0:10')

  // tu sie wszystko wylicza
  const countTimes = () => {
    if (timeman.todayWorkTime && (timeman.duration || timeman.totalTime)) {
      let diff = toSeconds(timeman.totalTime) - toSeconds(timeman.todayWorkTime)
      // jesli pole total nie bylo edytowane zeruje nadwyzki/niedobory czasu total wg marginesow
      if (diff >= minMargin && diff <= maxMargin && !wasEdited && (
        // 1. Jeśli czas Bx od 7:55 do 7:99 i nie ma nadgodzin z poprz. -> łączny czas = 8:00
        (diff < 0 && toSeconds(timeman.prevDayOverTime) <= 0) ||
        // 2. Jeśli czas Bx od 8:01 do 8:10 i nie ma niedoboru z poprz.-> łączny czas = 8:00
        (diff > 0 && toSeconds(timeman.prevDayOverTime) >= 0)
      )) {
        timeman.totalTime = timeman.todayWorkTime
        diff = 0
      }
      const prevOverTime = toSeconds(timeman.prevDayOverTime)
      const count = prevOverTime + diff

      timeman.workedOffTime = ''
      timeman.overTime = ''
      timeman.usedOverTime = ''
      timeman.lackOfDayTime = ''

      // gdy czas pracy jest wiekszy niz wymagany z wymiaru
      if (diff >= 0) {
        timeman.baseTime = timeman.todayWorkTime
        timeman.workedOffTime = ((prevOverTime <= 0) && (diff > 0)) ? diff <= -(prevOverTime) ? toHours(diff) : toHours(prevOverTime) : ''
        timeman.overTime = ((diff > -(prevOverTime)) && (diff > 0)) ? toHours(diff - toSeconds(timeman.workedOffTime)) : ''
      } else {
        timeman.baseTime = timeman.totalTime
        // gdy istnieją dostępne nadgodziny
        if (prevOverTime > 0) {
          timeman.usedOverTime = count >= 0 ? toHours(diff) : toHours(prevOverTime)
          timeman.lackOfDayTime = count < 0 ? toHours(count) : ''
        } else {
          timeman.usedOverTime = ''
          timeman.lackOfDayTime = toHours(diff)
        }
      }
      timeman.nextDayOverTime = `${count < 0 ? '-' : ''}${toHours(count)}`
      updateTimeman(timeman)
    }
  }

  // wyswietlanie komentarza czasu pracy
  const [showTotalTimeComment, setShowTotalTimeComment] = useState(false)

  // wylicza czas zakonczenia pracy z poczatku i czasu trwania pracy
  const finishFromStartAndTotalTime = () => {
    if (timeman.scStart && timeman.totalTime) {
      timeman.scFinish = timeman.scStart && toHours(toSeconds(timeman.scStart) + toSeconds(timeman.totalTime))
    }
  }

  useEffect(() => {
    if (loader === false) {
      if (wasConfirmed === false) {
        correctWorkTime()
        finishFromStartAndTotalTime()
        countTimes()
      }
      if (timeman.lackOfDayTime) {
        return setShowTotalTimeComment(true)
      }
      setShowTotalTimeComment(false)
    }
  }, [timeman.todayWorkTime, timeman.freeHours, timeman.scStart, timeman.totalTime, loader, timeman.confirmed])

  return (
    <div className='TimeReportOvertime blue-tooltip' style={{ minHeight: 180 }}>
      <div className={info && 'info'}>{info}</div>
      {
        !loader &&
          <Table className={info && 'noEvents'}>
            <thead>
              <tr>
                <th style={{ width: '50%' }} colSpan={4}>
                  {
                  allowEdit &&
                    <>
                      <ToggleSwitch
                        checked={shorterDaySwitch}
                        style={{ marginTop: '0.75rem', marginLeft: '0.5rem' }}
                        name='shorterDay'
                        label={tooltips.shorterDaySwitch.title}
                        toggleSwitch={toggleShorterDaySwitch}
                      />
                      <Tooltip>{tooltips.shorterDaySwitch.info}</Tooltip>
                    </>
                }
                  {
                  !loader &&
                  timeman.confirmed &&
                  wasConfirmed &&
                  allowReset &&
                    <input
                      type='button'
                      className='btn btn-sm'
                      style={{
                        margin: 0,
                        padding: '0 6px'
                      }}
                      onClick={() => resetMode()}
                      value='Popraw czas pracy'
                    />
                }
                </th>
                {/* {console.log(`confirmActive: ${state.confirmActive} | timemanConfirmed: ${timeman.confirmed} | ${!state.confirmActive || timeman.confirmed}`)} */}
                <th style={{ width: '20%', textAlign: 'right' }}>
                  <input
                    type='button'
                    disabled={!state.confirmActive || timeman.confirmed}
                    className={'btn btn-sm ' + (loader ? ' btn-default' : (state.confirmActive ? (timeman.confirmed ? ' btn-success' : ' btn-primary') : ' btn-danger'))}
                    style={{
                      display: (day === moment().format('YYYY-MM-DD') ? btxLogOut ? 'none' : 'inline-block' : 'inline-block'),
                      margin: 0,
                      padding: '0 6px'
                    }}
                    onClick={() => confirmDay()}
                    value={(timeman.confirmed ? 'Zatwierdzony' : 'Zatwierdź')}
                  />
                  <input
                    type='button'
                    className='btn btn-primary btn-sm'
                    style={{
                      display: (day === moment().format('YYYY-MM-DD') ? btxLogOut ? 'inline-block' : 'none' : 'none'),
                      margin: 0,
                      padding: '0 6px'
                    }}
                    onClick={() => bitrixLogout()}
                    value='Zakończ dzień w bitrix'
                  />
                </th>
                <th style={{ width: '20%' }}>{tooltips.workTime.title}<Tooltip>{tooltips.workTime.info}</Tooltip></th>
                <th style={{ width: '10%' }}><ReadOnlyinput value={loader ? '' : timeman.workTime} /></th>
              </tr>
              {
              shorterDaySwitch
                ? <tr>
                  <th style={{ width: '25%' }} colSpan={2}>{tooltips.freeHoursInfo.title}<Tooltip>{tooltips.freeHoursInfo.info}</Tooltip></th>
                  <th style={{ width: '45%' }} colSpan={3}>
                    <input
                      placeholder='Skomentuj przyczynę krótszego dnia pracy'
                      className='border-input'
                      style={{ fontSize: '2rem' }}
                      name='freeHoursInfo'
                      onChange={handleChange}
                      value={timeman.freeHoursInfo || ''}
                      readOnly={!allowEdit}
                    />
                  </th>
                  <th style={{ width: '20%' }}><b>{tooltips.freeHours.title}</b><Tooltip>{tooltips.freeHours.info}</Tooltip></th>
                  <th style={{ width: '10%' }}>
                    <input
                      className='border-input'
                      name='freeHours'
                      style={{ fontSize: '2rem', textAlign: 'right' }}
                      onChange={(e) => {
                        if (toSeconds(e.target.value) <= toSeconds(timeman.workTime)) {
                          handleChange(e)
                        }
                      }}
                      onBlur={() => {
                        timeman.freeHours = toShortHours(timeman.freeHours)
                        updateTimeman(timeman)
                      }}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          updateTimeman(timeman)
                        }
                      }}
                      value={timeman.freeHours || ''}
                      readOnly={!allowEdit}
                    />
                  </th>
                  </tr>
                : null
            }
              {
              shorterDaySwitch
                ? <tr>
                  <th style={{ width: '70%' }} colSpan={5} />
                  <th style={{ width: '20%' }}>{tooltips.correctedWorkTime.title}<Tooltip>{tooltips.correctedWorkTime.info}</Tooltip></th>
                  <th style={{ width: '10%' }}><ReadOnlyinput value={timeman.correctedWorkTime} /></th>
                  </tr>
                : null
            }
              <tr className='bg-lightgrey'>
                <th style={{ width: '70%' }} colSpan={5} />
                <th style={{ width: '20%' }} className='bg-grey'>{tooltips.duration.title}<Tooltip>{tooltips.duration.info}</Tooltip></th>
                <th style={{ width: '10%' }} className='bg-grey'><ReadOnlyinput value={timeman.duration} /></th>
              </tr>
              <tr className='bg-lightgrey'>
                {
                !showTotalTimeComment
                  ? <th style={{ width: '70%' }} colSpan={5}>&nbsp;</th>
                  : <>
                    <th style={{ width: '25%' }} colSpan={2}>{tooltips.lackOfDayTimeInfo.title}<Tooltip>{tooltips.lackOfDayTimeInfo.info}</Tooltip></th>
                    <th style={{ width: '45%' }} colSpan={3}>
                      <textarea
                        placeholder='Skomentuj przyczynę krótszego czasu pracy'
                        className='border-input'
                        style={{ fontSize: '2rem' }}
                        onChange={changeTimemanInfo}
                        value={timeman.info || ''}
                        readOnly={!allowEdit}
                      />
                    </th>
                    </>
              }
                <th style={{ width: '20%' }} className='bg-grey'><b>{tooltips.totalTime.title}</b><Tooltip>{tooltips.totalTime.info}</Tooltip></th>
                <th style={{ width: '10%' }} className='bg-grey'>
                  <input
                    className='border-input'
                    name='totalTime'
                    readOnly={!allowEdit}
                    style={{ fontSize: '2rem', textAlign: 'right', fontWeight: 'bold' }}
                    onChange={(e) => {
                      if ((toSeconds(e.target.value) +
                      toSeconds(timeman.scStart)) <= toSeconds('24:00')) {
                        handleChange(e)
                      }
                      setWasEdited(true)
                    }}
                    onBlur={() => {
                      timeman.totalTime = toShortHours(timeman.totalTime)
                      updateTimeman(timeman)
                    }}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        updateTimeman(timeman)
                      }
                    }}
                    value={timeman.totalTime || ''}
                  />
                </th>
              </tr>
              <tr className='bg-lightgrey'>
                <th style={{ width: '70%' }} colSpan={5} />
                <th style={{ width: '20%' }} className='bg-grey'>{tooltips.baseTime.title}<Tooltip>{tooltips.baseTime.info}</Tooltip></th>
                <th style={{ width: '10%' }} className='bg-grey'><ReadOnlyinput value={timeman.baseTime} /></th>
              </tr>
              {
              timeman.workedOffTime
                ? <tr className='bg-lightgrey'>
                  <th style={{ width: '70%' }} colSpan={5} />
                  <th style={{ width: '20%' }} className='bg-grey'>Odpracowane</th>
                  <th style={{ width: '10%' }} className='bg-grey'><ReadOnlyinput value={timeman.workedOffTime} /></th>
                  </tr>
                : null
            }
              {
              timeman.overTime
                ? <tr className='bg-lightgrey'>
                  <th style={{ width: '25%' }} colSpan={2}>{tooltips.overTimeInfo.title}<Tooltip>{tooltips.overTimeInfo.info}</Tooltip></th>
                  <th style={{ width: '45%' }} colSpan={3}>
                    <textarea
                      placeholder='Skomentuj przyczynę nadgodzin'
                      className='border-input'
                      style={{ fontSize: '2rem' }}
                      onChange={changeTimemanInfo}
                      value={timeman.info || ''}
                      readOnly={loader || wasConfirmed}
                    />
                    {
                      (toSeconds(timeman.overTime) >= 30 * 60) && (
                        <span>
                          <br />
                          <input
                            type='checkbox'
                            id='overTimeAgree'
                            checked={timeman.overTimeAgree ? timeman.overTimeAgree : false}
                            onChange={() => {
                              timeman.overTimeAgree = !timeman.overTimeAgree
                              updateTimeman(timeman)
                            }}
                            disabled={!allowEdit}
                          />
                          <label htmlFor='overTimeAgree'>&nbsp; {tooltips.overTimeAgree.title}<Tooltip>{tooltips.overTimeAgree.info}</Tooltip></label>
                        </span>
                      )
                    }
                  </th>
                  <th style={{ width: '20%' }} className='bg-grey'>{tooltips.overTime.title}<Tooltip>{tooltips.overTime.info}</Tooltip></th>
                  <th style={{ width: '10%' }} className='bg-grey'><ReadOnlyinput value={timeman.overTime} /></th>
                  </tr>
                : null
            }
              {
              timeman.usedOverTime
                ? <tr className='bg-lightgrey'>
                  <th style={{ width: '70%' }} colSpan={5}>&nbsp;</th>
                  <th style={{ width: '20%' }} className='bg-grey'>Odebrane nadgodziny</th>
                  <th style={{ width: '10%' }} className='bg-grey'><ReadOnlyinput value={timeman.usedOverTime} /></th>
                  </tr>
                : null
            }
              {
              timeman.lackOfDayTime
                ? <tr className='bg-lightgrey'>
                  <th style={{ width: '70%' }} colSpan={5}>&nbsp;</th>
                  <th style={{ width: '20%' }} className='bg-grey'>{tooltips.lackOfDayTime.title}<Tooltip>{tooltips.lackOfDayTime.info}</Tooltip></th>
                  <th style={{ width: '10%' }} className='bg-grey'><ReadOnlyinput value={timeman.lackOfDayTime} /></th>
                  </tr>
                : null
            }
              <tr>
                <th style={{ width: '30%' }} colSpan={3} rowSpan={2}>Nadgodziny/niedobór czasu pracy</th>
                <th style={{ width: '20%' }}>{tooltips.prevDayOverTime.title}<Tooltip>{tooltips.prevDayOverTime.info}</Tooltip></th>
                <th style={{ width: '10%' }}><ReadOnlyinput value={timeman.prevDayOverTime} /></th>
                <th style={{ width: '20%' }}>{tooltips.scStart.title}<Tooltip>{tooltips.scStart.info}</Tooltip></th>
                <th style={{ width: '10%' }}>
                  <input
                    className='border-input'
                    name='scStart'
                    style={{ fontSize: '2rem', textAlign: 'right' }}
                    onChange={handleChange}
                    onBlur={() => {
                      timeman.scStart = toShortHours(timeman.scStart)
                      updateTimeman(timeman)
                    }}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        updateTimeman(timeman)
                      }
                    }}
                    value={timeman.scStart || ''}
                    readOnly={!allowEdit}
                  />
                </th>
              </tr>
              <tr>
                <th style={{ width: '20%' }}>{tooltips.nextDayOverTime.title}<Tooltip>{tooltips.nextDayOverTime.info}</Tooltip></th>
                <th style={{ width: '10%' }}><ReadOnlyinput value={timeman.nextDayOverTime} /></th>
                <th style={{ width: '20%' }}>{tooltips.scFinish.title}<Tooltip>{tooltips.scFinish.info}</Tooltip></th>
                <th style={{ width: '10%' }}><ReadOnlyinput value={timeman.scFinish} /></th>
              </tr>
            </thead>
          </Table>
      }
    </div>
  )
}

export default TimeReportOvertime
