import React, { Component } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import { StatsCard } from '../../components/StatsCard/StatsCard.jsx'
import TimeReportComponent from '../TimeReport/TimeReportComponent'
import { connect } from 'react-redux'
import VirtualRooms from './VirtualRooms'
import Modal from '../../components/Modal/Modal'
import MapWithUsers from '../../components/EmployeesLocations/Map.jsx'
import '../style.css'

// const API_SERVER = process.env.REACT_APP_API_SERVER || '..'

class Dashboard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      // jiraCount: '?',
      modal: false,
      loader: false
    }
    // this.getJiraStats()
  }

  // getJiraStats () {
  //   fetch(`${API_SERVER}/api/stats/jira`)
  //     .then(res => res.json())
  //     .catch(error => console.error('Error:', error))
  //     .then((response) => {
  //       // this.setState({ jiraCount: response.total });
  //     })
  // }

  renderModalContent () {
    return (
      <div>
        <h5>{`Trwa przenoszenie praw na plikach. Podsumowanie otrzymasz w wiadomości email wysłanej na adres: ${this.props.user.EMAIL}`}</h5>
      </div>
    )
  }

  render () {
    return (
      <div className='content'>
        <Grid fluid>
          <Row>
            <Col lg={12} sm={12}>
              <VirtualRooms />
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col lg={12} sm={12}>
              <TimeReportComponent propActive propDisableHideAccepted />
            </Col>
          </Row>
          <Row style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Col lg={4} sm={12}>
              <a href='https://ui.system.netsalesmedia.pl/app/index.html#/' target='_blank' rel='noopener noreferrer'>
                <StatsCard
                  bigIcon={<i className='pe-7s-edit text-danger' />}
                  statsText='Ingenious'
                  statsValue='NetSalesMedia'
                  statsIcon={<i className='fa fa-link' />}
                  statsIconText='Zarządzanie siecią afiliacyjną'
                />
              </a>
            </Col>
            <Col lg={4} sm={12}>
              <a href='https://analytics.google.com/' target='_blank' rel='noopener noreferrer'>
                <StatsCard
                  bigIcon={<i className='pe-7s-graph2 text-danger' />}
                  statsText='Google'
                  statsValue='Analytics'
                  statsIcon={<i className='fa fa-link' />}
                  statsIconText='Statystyki klientów'
                />
              </a>
            </Col>
            <Col lg={4} sm={12}>
              <a href='http://cube.bitrix24.pl' target='_blank' rel='noopener noreferrer'>
                <StatsCard
                  bigIcon={<i className='pe-7s-portfolio text-success' />}
                  statsText='Firmowe'
                  statsValue='Bitrix'
                  statsIcon={<i className='fa fa-link' />}
                  statsIconText='System zarządzania firmą'
                />
              </a>
            </Col>
            <Col lg={4} sm={12}>
              <a href='https://cubegroup.atlassian.net/servicedesk/customer/portal/2' target='_blank' rel='noopener noreferrer'>
                <StatsCard
                  bigIcon={<i className='pe-7s-drawer text-danger' />}
                  statsText='Pomocne'
                  statsValue='System Traffic'
                  statsIcon={<i className='fa fa-link' />}
                  statsIconText=' '
                />
              </a>
            </Col>
            <Col lg={4} sm={12}>
              <a href='http://pro.similarweb.com/' target='_blank' rel='noopener noreferrer'>
                <StatsCard
                  bigIcon={<i className='pe-7s-search text-success' />}
                  statsText='Narzędzia'
                  statsValue='SimilarWeb'
                  statsIcon={<i className='fa fa-link' />}
                  statsIconText=' '
                />
              </a>
            </Col>
            <Col lg={4} sm={12}>
              <a href='https://properad.pl/' target='_blank' rel='noopener noreferrer'>
                <StatsCard
                  bigIcon={<i className='pe-7s-cash text-danger' />}
                  statsText='Narzędzia'
                  statsValue='ProperAd'
                  statsIcon={<i className='fa fa-link' />}
                  statsIconText=' '
                />
              </a>
            </Col>
            <Col lg={4} sm={12}>
              <a href='http://akademia.cubegroup.pl/' target='_blank' rel='noopener noreferrer'>
                <StatsCard
                  bigIcon={<i className='pe-7s-like2 text-success' />}
                  statsText='Wiedza'
                  statsValue='Akademia CG'
                  statsIcon={<i className='fa fa-link' />}
                  statsIconText='Digital Marketing Academy for You!'
                />
              </a>
            </Col>
            <Col lg={4} sm={12}>
              <a href='https://cube.bitrix24.pl/knowledge/baza%20wiedzy/' target='_blank' rel='noopener noreferrer'>
                <StatsCard
                  bigIcon={<i className='pe-7s-glasses text-primary' />}
                  statsText='Wiedza'
                  statsValue='Baza Wiedzy'
                  statsIcon={<i className='fa fa-link' />}
                  statsIconText='Bitrix Baza Wiedzy'
                />
              </a>
            </Col>
            {
              this.props.user.absences && <Col lg={4} sm={12}>
                <StatsCard
                  bigIcon={<i className='pe-7s-plane text-primary' />}
                  statsHtml={
                    <table style={{ fontSize: '16px' }}>
                      <tbody>
                        <tr><td>Pula bieżący rok:</td><td>&nbsp;<b>{this.props.user.absences[0] || '-'}</b></td></tr>
                        <tr><td>Pozostałe z ub.r.:</td><td>&nbsp;<b>{this.props.user.absences[1] || '-'}</b></td></tr>
                        <tr><td>Wykorzystane i zaplanowane:</td><td>&nbsp;<b>{this.props.user.absences[2] || '-'}</b></td></tr>
                        <tr><td>w tym na żądanie:</td><td>&nbsp;<b>{this.props.user.absences[4] || '-'}</b></td></tr>
                        <tr><td><b>POZOSTAŁE:</b></td><td>&nbsp;<b>{this.props.user.absences[5] || '-'}</b></td></tr>
                      </tbody>
                    </table>
                    }
                  statsIconText='DNI URLOPOWE / NIEOBECNOŚCI'
                />
              </Col>
            }
          </Row>
          <hr />
          <Row style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Col lg={12} sm={12}>
              <MapWithUsers />
            </Col>
          </Row>
        </Grid>
        <div id='own-preloader' className={`${this.state.loader === true ? 'show' : ''}`}>
          <div id='own-loader' />
        </div>
        <Modal show={this.state.modal} content={this.renderModalContent()} handleClosing={() => this.setState({ modal: false })} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(mapStateToProps)(Dashboard)
