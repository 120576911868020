const API_SERVER = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : (process.env.REACT_APP_API_SERVER || '')

export const FETCH_EMPLOYEES_LOCATIONS_REQUEST = 'FETCH_EMPLOYEES_LOCATIONS_REQUEST'
export const FETCH_EMPLOYEES_LOCATIONS_SUCCESS = 'FETCH_EMPLOYEES_LOCATIONS_SUCCESS'
export const FETCH_EMPLOYEES_LOCATIONS_FAILURE = 'FETCH_EMPLOYEES_LOCATIONS_FAILURE'

export const fetchEmployeesLocationsRequest = () => ({
  type: FETCH_EMPLOYEES_LOCATIONS_REQUEST
})

export const fetchEmployeesLocationsSuccess = (data) => ({
  type: FETCH_EMPLOYEES_LOCATIONS_SUCCESS,
  payload: data
})

export const fetchEmployeesLocationsFailure = (error) => ({
  type: FETCH_EMPLOYEES_LOCATIONS_FAILURE,
  payload: error
})

export const fetchEmployeesLocations = () => {
  return async (dispatch) => {
    dispatch(fetchEmployeesLocationsRequest())
    try {
      console.log('Fetching employees locations...')
      const res = await fetch(`${API_SERVER}/api/employees-locations-info`)
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`)
      }
      const { payload } = await res.json()
      console.log('Received data:', payload)
      dispatch(fetchEmployeesLocationsSuccess(payload))
    } catch (error) {
      console.error('Error fetching employees locations:', error)
      dispatch(fetchEmployeesLocationsFailure(error.message))
    }
  }
}
