import React, { Component } from 'react'
import { Grid, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import NotificationSystem from 'react-notification-system'
import { connect } from 'react-redux'
import { style } from '../../../variables/Variables'
import { Card } from '../../../components/Card/Card'
import FORM_MODEL from './orders'
import DataGrid from '../Components/DataGrid'
import Loader from '../Components/Loader'
import { getMainMap, getMapLabel } from '../common'
import { API_SERVER } from '../authData'

class OrderList extends Component {
  constructor (props) {
    super(props)
    this.linkTo = this.linkTo.bind(this)
    this.state = {
      loader: true,
      month: '',
      user: { token: props.user ? props.user.token : '' },
      colNames: Object.keys(FORM_MODEL),
      colParams: {
        actions: { pl: '', width: 50, resizable: true, frozen: true },
        gen_sel: { pl: 'Przedrostek', width: 80, filterable: true, resizable: true, sortable: true },
        ...FORM_MODEL
      },
      loggedUser: props.fakeUser.ID ? props.fakeUser : props.user,
      mainMap: getMainMap(props.main),
      selectedYear: new Date().getFullYear()
    }
  }

  componentDidMount () {
    this.getOrders()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.main) !== JSON.stringify(this.props.main)) {
      const mainMap = getMainMap(this.props.main)
      this.setState({ mainMap })
    }
  }

  getOrders () {
    const { loggedUser, selectedYear } = this.state
    fetch(`${API_SERVER}/api/get-orders`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        person: loggedUser,
        year: selectedYear
      })
    })
      .then(res => res.json())
      .then(response => {
        if (response && response.success) {
          this.setState({ data: response.success, loader: false })
        } else {
          this.showNotification('error', response.error || 'Wystąpił błąd')
          console.error('Error:', response.error)
          this.setState({ loader: false })
        }
      })
      .catch(error => {
        this.showNotification('error', 'Wystąpił błąd')
        console.error('Error:', error)
        this.setState({ loader: false })
      })
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }

    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  renderUpdateCompanyButton () {
    const { token } = this.state.user
    return (
      <button
        className='btn btn-default' onClick={e => {
          token
            ? this.showNotification('info', 'Trwa aktualizacja bazy firm...', 15)
            : this.showNotification('error', 'Zamknij okno i zaloguj się ponownie.', 0)
          e.preventDefault()

          this.fetchBtxData(`${API_SERVER}/api/bitrix/update/btx_companies?token=${token}`, token)
        }}
      >Odśwież bazę firm
      </button>
    )
  }

  renderUpdateUsersButton () {
    const { token } = this.state.user
    return (
      <button
        className='btn btn-default' onClick={e => {
          token
            ? this.showNotification('info', 'Trwa aktualizacja bazy użytkowników...', 15)
            : this.showNotification('error', 'Zamknij okno i zaloguj się ponownie.', 0)
          e.preventDefault()

          this.fetchBtxData(`${API_SERVER}/api/bitrix/update-btx-users?token=${token}`, token)
          this.fetchBtxData(`${API_SERVER}/api/bitrix/update/btx_departments?token=${token}`, token)
        }}
      >Odśwież bazę użytkowników
      </button>
    )
  }

  linkTo (link) {
    this.props.history.push({ pathname: `${link}` })
  }

  renderDataGrid () {
    const { data = [], colParams, mainMap, clearAllFilters } = this.state
    const dontShow = ['_id', 'created_on', 'created_by', 'endDate', 'file']

    const columns = []
    Object.keys(colParams).forEach((col) => {
      if (!dontShow.includes(col)) {
        const obj = {
          key: col,
          name: colParams[col] ? colParams[col].pl : col,
          filterable: colParams[col].filterable,
          resizable: colParams[col].resizable,
          sortable: colParams[col].sortable,
          frozen: colParams[col].frozen,
          width: colParams[col].width
        }
        columns.push(obj)
      }
    })

    const rows = data.length
      ? data.map((job, key) => {
        let row = {}
        Object.keys(colParams).forEach((col) => {
          const value = col === 'company'
            ? getMapLabel(mainMap.companies, job[col])
            : col === 'responsible_person'
              ? getMapLabel(mainMap.users, job[col])
              : job[col]
          row = { ...row, [col]: value || '' }
        })
        return row
      })
      : []

    return (
      columns.length && rows.length
        ? <DataGrid
            columns={columns}
            rows={rows}
            linkTo={this.linkTo}
            table='orders'
            links={[
              { name: 'Edytuj', href: '/settlements/order/', id: 'order_id' }
            ]}
            clearAllFilters={clearAllFilters}
          />
        : null
    )
  }

  handleYearChange = (event) => {
    // Reset filtrów przed zmianą roku
    this.props.dispatch({
      type: 'SETTLEMENTS_FILTERS',
      filters: { filters: { params: {}, table: 'invoices' } }
    })

    this.setState({
      selectedYear: parseInt(event.target.value),
      loader: true
    }, () => {
      this.getOrders()
    })
  }

  render () {
    const { loader, clearAllFilters, selectedYear } = this.state
    const currentYear = new Date().getFullYear()
    const startYear = 2019
    const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i)

    return (
      <div className='content Orders'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Loader display={loader} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div className='breadcrumb'>
                    <Link to='/settlements'>Panel rozliczeń</Link> » <Link
                      to='/settlements/orders'
                                                                     >Lista zleceń
                                                                     </Link>
                  </div>
                   }
              />
            </Col>
          </Row>
          <div className={loader ? 'noEvents' : ''}>
            <Row>
              <Col md={12}>
                <div className='form-group' style={{ marginBottom: '20px' }}>
                  <select
                    className='form-control'
                    style={{ width: '200px', display: 'inline-block', marginRight: '10px', borderColor: 'rgb(82,168,236)', verticalAlign: 'middle' }}
                    value={selectedYear}
                    onChange={this.handleYearChange}
                  >
                    {years.map(year => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                  <Link to='/settlements/order/add' className='btn btn-success' style={{ marginRight: '10px' }} role='button'>Nowe zlecenie</Link>
                  <Button className='btn' onClick={() => this.setState({ clearAllFilters: !clearAllFilters })}>Usuń wszystkie filtry</Button>
                  {/* {this.renderUpdateCompanyButton()} */}
                  {/* {this.renderUpdateUsersButton()} */}
                </div>
                <Card
                  title='Lista Zleceń'
                  content={this.renderDataGrid()}
                />
              </Col>
            </Row>
          </div>
        </Grid>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    user: state.user,
    fakeUser: state.fakeUser,
    main: state.main
  }
}

export default connect(mapStateToProps)(OrderList)
