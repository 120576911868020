import React from 'react'
import './DataGrid.css'
import { connect } from 'react-redux'
import Select from 'react-select'

const {
  Filters: {
    AutoCompleteFilter
  }
} = require('react-data-grid-addons')

const selectStyles = {
  dropdownIndicator: style => ({ ...style, padding: 0, width: 15 }),
  control: style => ({ ...style, padding: '0 2px', minHeight: 20, borderRadius: 3, cursor: 'pointer', fontWeight: 400 }),
  clearIndicator: style => ({ ...style, padding: 0, width: 15 }),
  singleValue: style => ({ ...style, padding: 0 }),
  placeholder: style => ({ ...style, padding: 0 }),
  valueContainer: style => ({ ...style, padding: 0 }),
  option: style => ({ ...style, padding: 4, fontWeight: 400 })
}

class AutoCompleteFilter2 extends AutoCompleteFilter {
  constructor (props) {
    super(props)
    this.state = {
      ...this.state,
      placeholder: 'Szukaj',
      filters2: {},
      options: []
    }
  }

  componentDidMount () {
    const { filters } = this.props
    this.setFilters2(filters)
    this.setState({ options: this.getOptions(this.props) })
  }

  UNSAFE_componentWillReceiveProps (props) {
    const { filters = {} } = props
    this.setFilters2(filters)
    this.setState({ options: this.getOptions(props) })
  }

  getOptions (props) {
    if (props && props.getValidFilterValues) {
      return props.getValidFilterValues()
        .filter(item => item)
        .sort()
        .map(value => ({
          label: value,
          value
        }))
    }
    return []
  }

  setFilters2 (filters2) {
    this.setState({ filters2 })
  }

  handleChange = (selected) => {
    if (!selected) {
      this.props.onChange({
        filterTerm: null,
        column: this.props.column
      })
    } else {
      const value = selected.value
      this.props.onChange({
        filterTerm: [value],
        column: this.props.column,
        filterValues: this.filterValues,
        type: 'contains'
      })
    }
  }

  filterValues = (row, columnFilter, columnKey) => {
    if (!columnFilter.filterTerm) return true
    const filterValue = columnFilter.filterTerm[0]
    return row[columnKey] === filterValue
  }

  render () {
    const { filters2 = {}, options } = this.state
    const currentFilter = filters2[this.props.column.key]
    const currentValue = currentFilter
      ? {
          label: currentFilter.filterTerm[0],
          value: currentFilter.filterTerm[0]
        }
      : null

    return (
      <Select
        autosize={false}
        name={`filter-${this.props.column.key}`}
        options={options}
        placeholder={this.state.placeholder}
        onChange={this.handleChange}
        isClearable
        escapeClearsValue
        styles={selectStyles}
        value={currentValue}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    filters: state.settlements.filters.params
  }
}

export default connect(mapStateToProps)(AutoCompleteFilter2)
