import { combineReducers } from 'redux'
import { sessionReducer } from 'redux-react-session'
import user from './user'
import partners from './partners'
import clients from './clients'
import influencers from './influencers'
import vouchers from './vouchers'
import clicks from './clicks'
import tasks from './tasks'
import main from './main'
import orders from './orders'
import settlements from './settlements'
import fakeUser from './fakeUser'
import creations from './creations'
import reports_generated from './reports_generated'
import employeesLocations from './employeesLocations'

export default combineReducers({
  user,
  partners,
  clients,
  influencers,
  vouchers,
  clicks,
  tasks,
  orders,
  settlements,
  fakeUser,
  creations,
  reports_generated,
  main,
  session: sessionReducer,
  employeesLocations
})
