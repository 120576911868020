const { API_SERVER } = require('../authData')

const getInvoices = (user, slug, year) => new Promise((resolve, reject) => {
  fetch(`${API_SERVER}/api/get-invoices`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      person: user,
      _id: slug,
      year
    })
  })
    .then(res => res.json())
    .catch((err) => {
      console.error('Error:', err)
      reject()
    })
    .then((response) => {
      resolve(response)
    })
})

const getSettlements = ({ order_id = '-', month = '-', invoices = [], person }) => new Promise((resolve, reject) => {
  const filtered = {
    order_id,
    month
  }
  fetch(`${API_SERVER}/api/get-settlements`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      filtered,
      person
    })
  })
    .then(res => res.json())
    .catch((err) => {
      console.error('Error:', err)
      reject()
    })
    .then((response) => {
      if (!response || response.error) {
        reject(response && response.error)
      } else
      if (response.length) {
        const sum = {}
        const sumArray = []
        response.forEach((item) => {
          const key = item.resp_depart + '|' + item.validDate
          sum[key] = sum[key] || 0
          sum[key] += parseFloat(item.revenue)
        })
        Object.keys(sum).forEach((key) => {
          const keyArray = key.split('|')
          const revenue = sum[key] ? sum[key] : 0
          sumArray.push({
            department: keyArray[0],
            date: keyArray[1],
            revenue
          })
        })

        sumArray.forEach((el, key) => {
          let invoiced = 0
          invoices.forEach((el2) => {
            el2.entries.forEach((el3) => {
              if (el3.department === el.department && el3.date === el.date) {
                invoiced += parseFloat(el3.amount)
              }
            })
          })
          sumArray[key] = Object.assign(el, {
            invoiced: parseFloat(invoiced),
            rest: el.revenue - parseFloat(invoiced)
          })
        })
        resolve(sumArray)
      } else {
        resolve([])
      }
    })
})

const format2d = (value) => {
  const dotValue = parseFloat(`${value}`.replace(/ /g, '').replace(',', '.'))
  if (isNaN(dotValue)) {
    return ('')
  }
  return (Math.round(dotValue * 100) / 100).toFixed(2)
}

module.exports = {
  getInvoices,
  getSettlements,
  format2d
}
