import React, { Component } from 'react'
import './DataGrid.css'
import { connect } from 'react-redux'
import AutoCompleteFilter2 from './AutoCompleteFilter2'
import NumberFormat from './NumberFormat'

const ReactDataGrid = require('react-data-grid')

const {
  // Toolbar,
  // Filters: {
  // NumericFilter,
  // AutoCompleteFilter,
  // MultiSelectFilter,
  // SingleSelectFilter
  // },
  Data: { Selectors }
  // DraggableHeader: { DraggableContainer }
} = require('react-data-grid-addons')

class Toolbar extends Component {
  componentDidMount () {
    this.props.onToggleFilter()
  }

  render () {
    return null
  }
}

class DataGrid extends Component {
  constructor (props, context) {
    super(props, context)
    this.setFilters = props.setFilters
    this._columns = props.columns.map(col => ({
      ...col,
      filterRenderer: props => (
        <AutoCompleteFilter2
          {...props}
          getValidFilterValues={() => this.getValidFilterValues(col.key)}
        />
      ),
      formatter: prop => col.editable === true && (props.isFinance || prop.row.settled !== 'tak')
        ? <div className='editable'><NumberFormat value={prop.value} /></div>
        : col.type === 'amount'
          ? <div className='amount'><NumberFormat value={prop.value} /></div>
          : <div>{prop.value}</div>
    }))
    this.state = {
      rows: props.rows,
      initialRows: props.rows,
      filters: {},
      selectedIndexes: [],
      selectedIds: [],
      columns: this._columns,
      height: 0
    }
  }

  componentDidMount () {
    const { filters, table } = this.props
    if (filters.table === table) {
      this.setState({ filters: filters.params })
    } else {
      this.setFilters({ filters: { params: {}, table: {} } })
    }
    this.setState({
      selectedIndexes: [],
      selectedIds: [],
      height: window.innerHeight
    })
  }

  // UNSAFE_componentWillReceiveProps(prevProps) {
  //   // this.setState({ rows: props.rows });
  //   if (this.state.rows !== this.props.rows) {
  //     console.log(58)
  //     this.setState({ rows: this.props.rows });
  //   }
  // }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.clearAllFilters !== this.props.clearAllFilters) {
      this.onClearFilters()
      this.setFilters({ filters: { params: {}, table: this.props.table } })
    }
    if (JSON.stringify(prevProps.rows) !== JSON.stringify(this.props.rows)) {
      // Aktualizacja kolumn z nowymi danymi
      const updatedColumns = this._columns.map(col => ({
        ...col,
        filterRenderer: props => (
          <AutoCompleteFilter2
            {...props}
            getValidFilterValues={() => this.getValidFilterValues(col.key)}
          />
        )
      }))

      // Reset filtrów i aktualizacja stanu
      this.onClearFilters()
      this.setFilters({ filters: { params: {}, table: this.props.table } })

      this.setState({
        rows: this.props.rows,
        initialRows: this.props.rows,
        selectedIndexes: [],
        selectedIds: [],
        columns: updatedColumns
      })
    }
  }

  getRows = () => {
    const { rows, filters } = this.state
    return Selectors.getRows({ rows, filters })
  }

  getSize = () => {
    return this.getRows().length
  }

  rowGetter = (rowIdx) => {
    const rows = this.getRows()
    return rows[rowIdx]
  }

  handleFilterChange = (filter) => {
    const newFilters = Object.assign({}, this.state.filters)
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter
    } else {
      delete newFilters[filter.column.key]
    }
    this.setState({ filters: newFilters, selectedIndexes: [], selectedIds: [] })
    this.setFilters({ filters: { params: newFilters, table: this.props.table } })
  }

  getValidFilterValues = (columnId) => {
    const values = this.state.rows.map(r => r[columnId])
    return values.filter((item, i, a) => {
      return item && i === a.indexOf(item)
    }).sort()
  }

  onClearFilters = () => {
    // all filters removed
    this.setState({ filters: {}, selectedIndexes: [], selectedIds: [] })
  }

  // rowRenderer = ({renderBaseRow, ...canvasProps}) => {
  //   const color = canvasProps.idx % 2 ? 'rgba(0,0,0,0.03)' : '#fff';
  //   return <div style={{ background: color }}>{renderBaseRow(canvasProps)}</div>;
  // };

  getCellActions = (column, row) => {
    const { links } = this.props
    const linkActions = links.map(link => (
      {
        icon: <span className='ico2'>{link.name}</span>,
        callback: () => {
          if (link.hasOwnProperty('month')) {
            // włącza filtr dla tabeli settlements, by wyswietlic listę rozliczen dot. podzlecenia
            //
            const filters = {
              params: {
                month: { filterType: 'text', type: 'contains', filter: link.month },
                job_id: { filterType: 'text', type: 'contains', filter: row[link.id] }
              },
              table: 'settlements',
              timeRange: 'all'
            }
            this.setFilters({ filters })
            this.props.linkTo(`${link.href}`)
          } else {
            this.props.linkTo(`${link.href}${row[link.id]}`)
          }
        }
      }
    ))

    const cellActions = {
      actions: linkActions
    }
    return cellActions[column.key]
  }

  onRowsSelected = rows => {
    this.setState({
      selectedIndexes: this.state.selectedIndexes.concat(
        // rows.map(r => r.row._id)
        rows.map(r => r.rowIdx)
      ),
      selectedIds: this.state.selectedIds.concat(
        rows.map(r => r.row)
      )
    }, () => this.props.setCheckboxesList(this.state.selectedIds))
  }

  onRowsDeselected = rows => {
    const rowIndexes = rows.map(r => r.rowIdx)
    const rowIds = rows.map(r => r.row._id)
    this.setState({
      selectedIndexes: this.state.selectedIndexes.filter(
        i => rowIndexes.indexOf(i) === -1
      ),
      selectedIds: this.state.selectedIds.filter(
        i => !rowIds.includes(i._id)
      )
    }, () => this.props.setCheckboxesList(this.state.selectedIds))
  }

  format2d (value) {
    if (value) {
      const dotValue = parseFloat(value.replace(',', '.'))
      if (isNaN(dotValue)) { return '' }
      return (Math.round(dotValue * 100) / 100).toFixed(2)
    }
  };

  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    this.setState(state => {
      const rows = state.rows.slice()
      for (let i = fromRow; i <= toRow; i++) {
        const editable = ['revenue', 'cost', 'VR_cost']
        editable.forEach(el => updated[el] ? updated[el] = this.format2d(updated[el]) : updated[el])
        rows[i] = { ...rows[i], ...updated }
      }
      if (this.props.isFinance || this.state.rows[toRow].settled !== 'tak') {
        //
        // Obliczenia
        //
        rows.forEach(el => {
          el.total_costs = [el.cost || 0, el.VR_cost || 0]
            .map(Number).reduce((a, b) => a + b).toFixed(2)
          el.profit = [el.revenue || 0, el.total_costs || 0]
            .map(Number).reduce((a, b) => a - b).toFixed(2)
        })
        return { rows }
      }
    }, () => this.props.setNewData(this.state.rows))
  }

  sortRows = (initialRows, sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === 'ASC') {
        return a[sortColumn] > b[sortColumn] ? 1 : -1
      } else if (sortDirection === 'DESC') {
        return a[sortColumn] < b[sortColumn] ? 1 : -1
      }
    }
    this.setState({
      rows: sortDirection === 'NONE' ? initialRows : [...initialRows].sort(comparer)
    })
  }

  /* metoda do zmiany kolejnosci kolumn */

  // onHeaderDrop = (source, target) => {
  //   const stateCopy = Object.assign({}, this.state);
  //   const columnSourceIndex = this.state.columns.findIndex(
  //     i => i.key === source
  //   );
  //   const columnTargetIndex = this.state.columns.findIndex(
  //     i => i.key === target
  //   );
  //   stateCopy.columns.splice(
  //     columnTargetIndex,
  //     0,
  //     stateCopy.columns.splice(columnSourceIndex, 1)[0]
  //   );
  //
  //   const emptyColumns = Object.assign({}, this.state, { columns: [] });
  //   this.setState(emptyColumns);
  //
  //   const reorderedColumns = Object.assign({}, this.state, {
  //     columns: stateCopy.columns
  //   });
  //   this.setState(reorderedColumns);
  // };

  render () {
    const { initialRows, height } = this.state
    return (
      <div>
        {/* <DraggableContainer onHeaderDrop={this.onHeaderDrop}> */}
        <ReactDataGrid
          columns={this.state.columns}
          rowGetter={this.rowGetter}
          rowsCount={this.getSize()}
          minHeight={height * 0.65}
          // rowHeight={30}
          // headerRowHeight={30}
          minColumnWidth={100}
          toolbar={<Toolbar enableFilter />}
          enableCellSelect
          onAddFilter={this.handleFilterChange}
          getValidFilterValues={this.getValidFilterValues}
          onClearFilters={this.onClearFilters}
          // rowRenderer={this.rowRenderer}
          getCellActions={(column, row) => this.getCellActions(column, row)}
          rowSelection={{
            showCheckbox: this.props.showCheckbox || false,
            enableShiftSelect: true,
            onRowsSelected: this.onRowsSelected,
            onRowsDeselected: this.onRowsDeselected,
            selectBy: {
              indexes: this.state.selectedIndexes
            }
          }}
          onGridRowsUpdated={this.onGridRowsUpdated}
          onGridSort={(sortColumn, sortDirection) =>
            this.sortRows(initialRows, sortColumn, sortDirection)}
        />
        {/* </DraggableContainer> */}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    filters: state.settlements.filters
  }
}

const mapDispatchToProps = {
  setFilters: data => ({
    type: 'SETTLEMENTS_FILTERS',
    filters: data
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(DataGrid)
