import React, { Component } from 'react'
import { DropdownList } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css'

class ScDropdownList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: props.value || '',
      options: props.options || [],
      disabled: props.disabled
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value || '' })
    }
    if (nextProps.options !== this.props.options) {
      this.setState({ options: nextProps.options || [] })
    }
  }

  handleChange (value) {
    this.setState({ value }, () =>
      this.props.getValue(value, this.props.elem))
  }

  handleCreate (name) {
    const { options } = this.state
    const newValue = name.trim()
    if (newValue) {
      this.setState({
        value: newValue,
        options: [...options, newValue]
      }, () => this.props.getValue(newValue, this.props.elem))
    }
  }

  render () {
    const { value, options, disabled } = this.state
    return (
      <DropdownList
        data={options}
        value={value}
        disabled={disabled}
        filter
        allowCreate='onFilter'
        onChange={value => this.handleChange(value)}
        onCreate={name => this.handleCreate(name)}
      />
    )
  }
}

export default ScDropdownList
