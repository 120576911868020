import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import NotificationSystem from 'react-notification-system'
import Card from '../../../components/Card/Card.jsx'
import { formInvoice, statusCode } from './invoices'
import { style } from '../../../variables/Variables'
import { getInvoices } from './common'
import Loader from '../Components/Loader'
import DataGrid from '../Components/DataGrid'
import { Link } from 'react-router-dom'
import { getMainMap, getDepartmentsMap, getMapLabel } from '../common'
import { checkUserType } from '../authData'

class InvoiceList extends Component {
  constructor (props) {
    super(props)
    this.linkTo = this.linkTo.bind(this)
    this.state = {
      loader: true,
      colParams: formInvoice,
      statusCode,
      loggedUser: props.fakeUser.ID ? props.fakeUser : props.user,
      mainMap: getMainMap(props.main),
      statusMap: getDepartmentsMap(statusCode),
      selectedYear: new Date().getFullYear()
    }
  }

  componentDidMount () {
    const { loggedUser } = this.state
    const isFinance = checkUserType(loggedUser, 'finance')
    this.setState({
      isFinance
    })
    this.loadNext()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.main) !== JSON.stringify(this.props.main)) {
      const mainMap = getMainMap(this.props.main)
      this.setState({ mainMap })
    }
  }

  loadNext () {
    const { loggedUser, selectedYear } = this.state
    getInvoices(loggedUser, null, selectedYear)
      .catch(() => this.showNotification('error', 'Wystąpił błąd'))
      .then(res => {
        if (!res || res.error) {
          this.showNotification('error', res && res.error)
          this.setState({ loader: false })
        } else {
          this.setState({
            data: res.result,
            loader: false
          }, () => {
            this.prepareRows()
            this.prepareCols()
          })
        }
      })
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }

    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  renderInvoicesTableHeader () {
    const { colParams } = this.state
    const dontShow = ['_id', 'sum', 'comment']
    const style = col => ({
      display: dontShow.includes(col) ? 'none' : '',
      width: colParams[col] ? colParams[col].width : ''
    })
    return Object.keys(colParams).map((col, key) =>
      <th key={key} style={style(col)}>{colParams[col] ? colParams[col].pl : col}</th>
    )
  }

  renderInvoicesTableContent () {
    const { data = [], colParams, mainMap, statusMap } = this.state
    const dontShow = ['_id', 'sum', 'comment']

    return data.length
      ? data.map((elem, key) => (
        <tr
          key={key}
          style={{ cursor: 'pointer' }}
          className={!['2'].includes(elem.status) ? 'closed' : ''}
          onClick={() => {
            this.props.history.push({
              pathname: `/settlements/invoice/${elem._id}`
            })
          }}
        >
          {Object.keys(colParams).map((col, key2) => (
            !dontShow.includes(col)
              ? <td key={key2}>{col === '_id'
                ? key + 1
                : col === 'company'
                  ? `${getMapLabel(mainMap.companies, elem[col])} (${getMapLabel(mainMap.companies, elem[col], 'nip')})`
                  : col === 'mainDepart'
                    ? getMapLabel(mainMap.departments, elem[col])
                    : col === 'mainPerson'
                      ? getMapLabel(mainMap.users, elem[col])
                      : col === 'status' ? getMapLabel(statusMap, elem[col]) : elem[col]}
              </td>
              : null
          ))}
        </tr>
      )
      )
      : ''
  }

  linkTo (link) {
    // Faktury będą otwierane w nowym oknie
    window.open(link)
  }

  prepareRows () {
    const { data = [], colParams, mainMap, statusMap } = this.state
    const rows = data.length
      ? data.map((invoice) => {
        let row = {}
        Object.keys(colParams).forEach((col) => {
          const value =
          col === 'company'
            ? `${getMapLabel(mainMap.companies, invoice[col])} ${getMapLabel(mainMap.companies, invoice[col], 'nip')}`
            : col === 'mainDepart'
              ? getMapLabel(mainMap.departments, invoice[col])
              : col === 'mainPerson'
                ? getMapLabel(mainMap.users, invoice[col])
                : col === 'status' ? getMapLabel(statusMap, invoice[col]) : invoice[col]
          row = { ...row, [col]: value || '' }
        })
        return row
      })
      : []
    this.setState({ preparedRows: rows })
  }

  prepareCols () {
    const { colParams } = this.state
    const dontShow = ['_id', 'sum', 'comment']

    const columns = []
    columns.push({ key: 'actions', name: '', width: 50 })
    Object.keys(colParams).forEach((col) => {
      if (!dontShow.includes(col)) {
        columns.push({
          key: col,
          name: colParams[col] ? colParams[col].pl : col,
          filterable: true,
          resizable: true,
          sortable: true,
          width: colParams[col].width
        })
      }
    })
    this.setState({ preparedCols: columns })
  }

  renderDataGrid () {
    const { preparedCols = [], preparedRows = [], clearAllFilters } = this.state

    return (
      preparedCols.length && preparedRows.length
        ? <DataGrid
            columns={preparedCols}
            rows={preparedRows}
            linkTo={this.linkTo}
            table='invoices'
            links={[
              { name: 'Edytuj', href: '/settlements/invoice/', id: '_id' }
            ]}
            clearAllFilters={clearAllFilters}
          />
        : null
    )
  }

  handleYearChange = (event) => {
    // Reset filtrów przed zmianą roku
    this.props.dispatch({
      type: 'SETTLEMENTS_FILTERS',
      filters: { filters: { params: {}, table: 'invoices' } }
    })

    this.setState({
      selectedYear: parseInt(event.target.value),
      loader: true
    }, () => {
      this.loadNext()
    })
  }

  render () {
    const { loader, clearAllFilters, selectedYear } = this.state
    const currentYear = new Date().getFullYear()
    const startYear = 2019
    const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i)

    return (
      <div className='content'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Loader display={loader} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div className='breadcrumb'>
                    <Link to='/settlements'>Panel rozliczeń</Link> » <Link to='/settlements/invoices'>Lista faktur</Link>
                  </div>
                }
              />
            </Col>
          </Row>
          <div className={loader ? 'noEvents' : ''}>
            <Row>
              <Col md={12}>
                <div className='form-group' style={{ marginBottom: '20px' }}>
                  <select
                    className='form-control'
                    style={{ width: '200px', display: 'inline-block', marginRight: '10px', borderColor: 'rgb(82,168,236)' }}
                    value={selectedYear}
                    onChange={this.handleYearChange}
                  >
                    {years.map(year => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                  <button
                    className='btn btn-default btn-sm'
                    style={{ margin: '0 10px' }}
                    onClick={() => this.setState({ clearAllFilters: !clearAllFilters })}
                  >
                    Usuń wszystkie filtry
                  </button>
                </div>
                <Card
                  title='Faktury'
                  content={this.renderDataGrid()}
                />
              </Col>
            </Row>
          </div>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    fakeUser: state.fakeUser,
    main: state.main
  }
}

export default connect(mapStateToProps)(InvoiceList)
