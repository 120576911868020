import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card } from '../../../components/Card/Card'
import NotificationSystem from 'react-notification-system'
import moment from 'moment'
import { style } from '../../../variables/Variables'
import { Col, FormControl, Grid, Row } from 'react-bootstrap'
import SCModal from '../Components/Modal'
import { Link } from 'react-router-dom'
import Loader from '../Components/Loader'
import AgGrid from '../Components/AgGrid'
import { formSettlement } from './settlements'
import { currencyFormatter, toFixed } from '../Components/currencyFormatter'
import { getMainMap, getMapLabel } from '../common'
import { checkConditions, checkValidation } from './validate'
import NumberFormat from '../Components/NumberFormat'
import { API_SERVER, checkUserType } from '../authData'

const FORM_MODEL = formSettlement

class SettlementList extends Component {
  constructor (props) {
    super(props)
    this.duplicateSettlements = this.duplicateSettlements.bind(this)
    this.settleData = this.settleData.bind(this)
    this.fixData = this.fixData.bind(this)
    this.setModalParams = this.setModalParams.bind(this)
    this.redirectToCorrection = this.redirectToCorrection.bind(this)
    this.setCheckboxesList = this.setCheckboxesList.bind(this)
    this.setFilters = props.setFilters
    this.state = {
      loader: true,
      colParams: FORM_MODEL,
      requiredForSettleButton: Object.keys(FORM_MODEL).filter(prop => FORM_MODEL[prop].req1 || FORM_MODEL[prop].req2),
      filtersData: {},
      timeRange: props.filters.timeRange || 'thisAndLastMonth',
      checkboxes: {
        list: [],
        month: ''
      },
      searchInput: '',
      loggedUser: props.fakeUser.ID ? props.fakeUser : props.user,
      mainMap: getMainMap(props.main)
    }
  }

  componentDidMount () {
    const { loggedUser } = this.state
    const isFinance = checkUserType(loggedUser, 'finance') || this.props.user.ID === '200'
    this.setState({
      isFinance
    }, () => { this.loadNext() })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.main) !== JSON.stringify(this.props.main)) {
      const mainMap = getMainMap(this.props.main)
      this.setState({ mainMap })
    }
  }

  loadNext () {
    const { loggedUser, timeRange } = this.state
    fetch(`${API_SERVER}/api/get-settlements`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        person: loggedUser,
        timeRange
      })
    })
      .then(res => res.json())
      .catch(error => {
        this.showNotification('error', 'Wystąpił błąd')
        console.error('Error:', error)
      })
      .then((response) => {
        if (!response || response.error) {
          this.showNotification('error', `Wystąpił błąd: ${(response && response.error) || ''}`)
          console.error('Error:', response && response.error)
          this.setState({ loader: false })
        } else
        if (response) {
          if (response.length) {
            response.map(el => el.settled = (el.settled === 'tak' ? el.settled : el.settled = 'nie'))
          }
          this.setState({ data: response, loader: false }, () => {
            this.prepareCols()
            this.prepareRows()
          })
        }
      })
  }

  showNotification (level, message, autoDismiss = 5) {
    const icons = {
      error: 'pe-7s-bandaid',
      success: 'pe-7s-check',
      info: 'pe-7s-cloud-upload'
    }

    this.refs.notificationSystem.addNotification({
      title: (<span data-notify='icon' className={icons[level]} />),
      message,
      level,
      position: 'br',
      autoDismiss
    })
  }

  getLabel (arr = [], row) {
    const obj = arr.length ? arr.find(o => o.ID === row) : ''
    return obj ? obj.NAME || obj.TITLE : '---'
  }

  redirectToCorrection () {
    const { checkboxes } = this.state
    const { list = [] } = checkboxes
    this.props.history.push({
      pathname: `/settlements/settlement/correction-${list.length ? list[0]._id : ''}`
    })
  }

  renderCorrectionButton () {
    const { checkboxes } = this.state
    const { list = [] } = checkboxes
    let isEnabled = false
    if (list.length === 1) {
      isEnabled = list.length === 1 && list[0].settled === 'tak' && !list[0].correction
    }

    const title = 'Korekta'
    const content = (
      <p>
        Czy chcesz wprowadzić Korektę dla zlecenia {list.length ? list[0].order_id : ''}?
      </p>
    )
    return (
      <button
        className={`btn btn-default btn-sm ${isEnabled ? 'btn-primary' : ''}`}
        disabled={!isEnabled}
        style={{ margin: 10 }}
        onClick={e => {
          e.preventDefault()
          isEnabled && this.setModalParams(true, title, content, this.redirectToCorrection)
        }}
      >
        Korekta
      </button>
    )
  }

  getRangeOfDates (start, end, key, arr = [start.startOf(key)]) {
    if (start.isAfter(end)) throw new Error('start must precede end')
    const next = moment(start).add(1, key).startOf(key)
    if (next.isAfter(end, key)) return arr
    return this.getRangeOfDates(next, end, key, arr.concat(next))
  }

  /**
   * Obsługa przycisku Duplikuj
   */

  duplicateSettlements () {
    this.setState({ loader: true })
    const { checkboxes } = this.state
    const { list = [], month } = checkboxes
    const settlementsList = list.map(el => el._id)

    fetch(`${API_SERVER}/api/duplicate-settlements`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        list: settlementsList,
        month
      })
    })
      .then(res => res.json())
      .catch(error => {
        this.showNotification('error', 'Wystąpił błąd')
        console.error('Error:', error)
      })
      .then(response => {
        const resSuccess = response.result[0].success || []
        if (resSuccess.length) {
          this.showNotification('success', `Rozliczenia zduplikowane: ${resSuccess.map(el => `[${el}]`)}`, 6)
        }
        const resError = response.result[0].error || []
        if (resError.length) {
          this.showNotification('error', `Rozliczenia poza zakresem podzleceń: ${resError.map(el => `[${el}]`)}`, 0)
        }
        const resCorrection = response.result[0].correction || []
        if (resCorrection.length) {
          this.showNotification('error', `Rozliczenia są korektami: ${resCorrection.map(el => `[${el}]`)}`, 0)
        }
        // const checkboxes = { ...this.state.checkboxes, month: '' };
        // lista chyba powinna byc jednak czyszczona
        const _checkboxes = { list: [], month: '' }
        this.setState({ _checkboxes, loader: false })
        this.loadNext()
      })
  }

  renderDuplicateButton () {
    const { checkboxes, loader } = this.state
    const { list = [] } = checkboxes
    const active = list.length > 0
    const rangeOfDates = this.getRangeOfDates(moment('2019-01-01'), moment(), 'month')
    const source = rangeOfDates.map(el => moment(el).format('YYYY-MM')).reverse()

    const title = 'Duplikaty'
    const renderContent = (month) => (
      <div>
        Czy chcesz zapisać Duplikaty dla <b>{list.length}</b> pozycji
        do miesiąca <b>{month}</b>?<br />
        <table className='modalTable'>
          <tbody>
            <tr>
              <th>Nr zlecenia</th>
              <th>Zleceniodawca</th>
            </tr>
            {list.map((el, key) =>
              <tr key={key}>
                <td>{el.order_id}</td>
                <td>{el.company}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )

    return (
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 30 }}>
        <FormControl
          type='select'
          value={checkboxes.month}
          className='btn btn-default'
          componentClass='select'
          disabled={!active || loader}
          onChange={(e) => {
            checkboxes.month = e.target.value
            this.setState({ checkboxes }, () => {
              if (checkboxes.month !== '') {
                this.setModalParams(true, title, renderContent(checkboxes.month), this.duplicateSettlements)
              }
            })
          }}
        >
          <option value=''> Duplikuj... </option>
          {
            source.map((el, key) => <option key={key} value={el}>{el}</option>)
          }
        </FormControl>
      </div>
    )
  }

  renderTimeRange () {
    const { timeRange } = this.state
    const { filters } = this.props
    const currentMonth = parseInt(moment().format('MM'), 10)
    const startYear = 2019
    const toYear = new Date().getFullYear() - 2
    const years = Array.from({ length: toYear - startYear + 1 }, (_, i) => startYear + i)

    return (
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 30 }}>
        <FormControl
          type='select'
          value={timeRange}
          className='btn btn-default'
          componentClass='select'
          onChange={(e) => {
            const value = e.target.value
            this.setState({
              timeRange: value,
              loader: true
            }, () => {
              filters.timeRange = value
              this.setFilters({ filters })
              this.loadNext()
            })
          }}
        >
          <option value='Q1'>I kwartał</option>
          <option value='Q2' disabled={currentMonth <= 3}>II kwartał</option>
          <option value='Q3' disabled={currentMonth <= 6}>III kwartał</option>
          <option value='Q4' disabled={currentMonth <= 9}>IV kwartał</option>
          <option value='thisAndLastMonth'>Bieżący i poprzedni miesiąc</option>
          <option value='lastThreeMonths'>Ostatnie 3 miesiące</option>
          <option value={new Date().getFullYear()}>Bieżący rok ({new Date().getFullYear()})</option>
          <option value={new Date().getFullYear() - 1}>Poprzedni rok ({new Date().getFullYear() - 1})</option>
          {years.sort((a, b) => b - a).map(el => <option key={el} value={el}>{el}</option>)}
        </FormControl>
      </div>
    )
  }

  renderSearchInput () {
    const { searchInput = '' } = this.state
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 30 }}>
        <FormControl
          type='input'
          value={searchInput}
          className={`btn btn-default ${searchInput !== '' ? ' not-empty' : ''}`}
          placeholder='Szukaj...'
          style={{ textAlign: 'left' }}
          onChange={(e) => {
            this.setState({ searchInput: e.target.value })
          }}
        />
      </div>
    )
  }

  renderSavedFilters () {
    const { filters } = this.props
    const { colParams = {} } = this.state
    const savedFilters = Object.keys(filters.params || {})

    const filterNames = savedFilters.length && filters.table === 'settlements'
      ? savedFilters.map(el => colParams[el] ? colParams[el].pl : '')
      : []

    return (
      filterNames.length
        ? <div style={{ display: 'flex', alignItems: 'center', marginLeft: 30 }}>
          <small style={{ color: '#777', fontSize: 12 }}>Aktywne Filtry: {filterNames.join(', ')}</small>
        </div>
        : null
    )
  }

  /**
   * Obsługa przycisku Rozlicz
   */

  dataConditions (data) {
    const { colParams, requiredForSettleButton } = this.state
    const req = requiredForSettleButton.filter(el => el !== 'settled')
    const conditions = checkConditions(data, colParams, [], req)
    return { ...conditions }
  }

  dataValidation (data, req) {
    return checkValidation(data, [], req, true)
  }

  settleData () {
    this.saveSettlements(true)
  }

  saveSettlements (settle) {
    const today = moment().format('YYYY-MM-DD')
    const { checkboxes } = this.state
    const { list = [] } = checkboxes
    const allowedSett = []
    const notAllowedSett = []

    list.forEach(settlement => {
      const condition = this.dataConditions(settlement)
      const formValid = this.dataValidation(settlement, condition.requiredForSettleButton)
      if (formValid) {
        allowedSett.push(settlement)
      } else {
        notAllowedSett.push(settlement)
      }
    })

    if (notAllowedSett.length) {
      this.showNotification('error',
        `Rozliczenia z błędami edytuj pojedynczo:
      ${notAllowedSett.map(el => ` ${el.order_id}`)}`, 0)
    }

    if (allowedSett.length) {
      this.setState({ loader: true })
      this.showNotification('info', 'Wysyłanie danych')
      const newArr = []
      allowedSett.forEach(el => {
        const newObj = {}
        if (settle) {
          newObj.settleDate = today
          newObj.settled = 'tak'
          newObj.validDate = el.validDate ? el.validDate : moment(el.month).endOf('month').format('YYYY-MM-DD')
        } else {
          newObj.fix = true
        }
        newObj._id = el._id
        newObj.revenue = toFixed(el.revenue)
        newObj.cost = toFixed(el.cost)
        newObj.VR_cost = toFixed(el.VR_cost || 0)
        newObj.total_costs = toFixed(el.total_costs)
        newObj.profit = toFixed(el.profit)
        newObj.real_profit = toFixed(el.real_profit)
        newObj.real_turnover = toFixed(el.real_turnover)
        newArr.push(newObj)
      })

      fetch(`${API_SERVER}/api/save-settlement`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newArr)
      })
        .then(res => res.json())
        .catch(error => {
          this.showNotification('error', 'Wystąpił błąd')
          console.error('Error:', error)
        })
        .then(response => {
          const resSuccess = response.result[0].success || null
          if (resSuccess.length) {
            this.showNotification('success', `Rozliczone: ${resSuccess.map(el => ` ${el}`)}`, 6)
          }
          const resError = response.result[0].error || null
          if (resError.length) {
            this.showNotification('error', `Nierozliczone: ${resError.map(el => ` ${el}`)}`, 0)
          }
          const _checkboxes = { list: [], month: '' }
          this.setState({ _checkboxes, loader: false }, () => this.loadNext())
        })
    }
  }

  renderSettleButton () {
    const { checkboxes, loader } = this.state
    const { list = [] } = checkboxes
    const isNotSettled = list.every(el => el.settled !== 'tak')
    const active = list.length && isNotSettled

    const title = 'Rozlicz'
    const content = (
      <div>
        Czy chcesz zatwierdzić Rozliczenia dla {list.length} pozycji
        {this.renderModalTable(list)}
      </div>
    )

    return (
      <button
        className={`btn btn-default btn-sm ${active ? 'btn-primary' : ''}`}
        disabled={!active || loader}
        style={{ margin: 10 }}
        onClick={() => this.setModalParams(true, title, content, this.settleData)}
      >Rozlicz
      </button>
    )
  }

  /**
   * Obsługa przycisku Popraw
   */

  fixData () {
    this.saveSettlements(false)
  }

  renderFixButton () {
    const { checkboxes, loader, data, isFinance } = this.state
    const { list = [] } = checkboxes
    const active = list.length > 0

    const title = 'Popraw'
    const content = (
      <div>
        Czy chcesz poprawić Rozliczenia dla {list.length} pozycji
        {this.renderModalTable(list, data)}
      </div>
    )

    return (
      isFinance
        ? <button
            className={`btn btn-default btn-sm ${active ? 'btn-primary' : ''}`}
            disabled={!active || loader}
            style={{ margin: 10 }}
            onClick={() => this.setModalParams(true, title, content, this.fixData)}
          >Popraw
          </button>
        : null
    )
  }

  renderClearAllFiltersButton () {
    const { loader, clearAllFilters } = this.state
    const { filters } = this.props
    const active = Object.keys(filters.params).length
    return (
      <button
        className='btn btn-default btn-sm'
        disabled={!active || loader}
        style={{ margin: 10 }}
        onClick={() => this.setState({ clearAllFilters: !clearAllFilters })}
      >Usuń wszystkie filtry
      </button>
    )
  }

  /**
   * Obsługa modala
   */

  setModalParams (show = false, title = '', content = '', method = null) {
    this.setState({
      modalShow: show,
      modalTitle: title,
      modalContent: content,
      modalMethod: method
    })
  }

  renderModal () {
    const { modalShow, modalTitle, modalContent, modalMethod } = this.state
    return (
      <SCModal
        modalShow={modalShow}
        modalTitle={modalTitle}
        modalContent={modalContent}
        setModalParams={this.setModalParams}
        propMethod={modalMethod}
      />
    )
  }

  renderModalTable (list) {
    return (
      <table className='modalTable'>
        <tbody>
          <tr>
            <th>Nr zlecenia</th>
            <th>Zleceniodawca</th>
            <th>Przychód</th>
            <th>Koszt</th>
          </tr>
          {list.map((el, key) =>
            <tr key={key}>
              <td>{el.order_id}</td>
              <td>{el.company}</td>
              <td><NumberFormat value={el.revenue} /></td>
              <td><NumberFormat value={el.cost} /></td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  /**
   * Obsługa danych z głównej tabeli
   */

  setCheckboxesList (_checkboxes) {
    const { checkboxes } = this.state
    checkboxes.list = _checkboxes
    this.setState({ ...checkboxes })
  }

  prepareRows () {
    const { data = [], colParams, mainMap } = this.state

    const rows = data.length
      ? data.map((settlement) => {
        let row = {}
        Object.keys(colParams).forEach((col) => {
          const value =
          col === 'company'
            ? `${getMapLabel(mainMap.companies, settlement[col])} ${getMapLabel(mainMap.companies, settlement[col], 'nip')}`
            : ['main_depart', 'from_depart', 'resp_depart'].includes(col)
                ? getMapLabel(mainMap.departments, settlement[col])
                : ['main_person', 'from_person', 'resp_person'].includes(col)
                    ? getMapLabel(mainMap.users, settlement[col])
                    : col === 'lastUpdate'
                      ? settlement.logs && `${moment(settlement.logs.update.timestamp).local().format('YYYY.MM.DD HH:mm')} ${getMapLabel(mainMap.users, settlement.logs.update.user)}`
                      : settlement[col]
          row = { ...row, [col]: value || '' }
        })
        return row
      })
      : []
    this.setState({ preparedRows: rows })
  }

  prepareCols () {
    const { colParams, isFinance } = this.state

    const colHeaders = ['Ogólne', 'Działy', 'Szczegóły', 'Wartości', 'Przedpłaty']

    const columns = colHeaders.map((col, key) => {
      const children = []
      Object.entries(colParams).forEach((row) => {
        if (row[1].col === key + 1) {
          children.push({
            field: row[0],
            headerName: row[1].pl,
            pinned: row[1].frozen ? 'left' : null,
            columnGroupShow: row[1].stickyCol ? '' : 'open',
            editable: params => row[1].editable &&
              (isFinance || ((params || {}).data || {}).settled !== 'tak') &&
              !((params || {}).data || {}).pinnedBottom,
            width: parseInt(row[1].width, 10) || null,
            filter: row[1].type === 'amount' ? 'agNumberColumnFilter' : 'agTextColumnFilter'
          })

          if (row[1].type === 'ag-date') {
            const lastElementWithDateFilter = children.pop()
            lastElementWithDateFilter.filter = 'agDateColumnFilter'
            lastElementWithDateFilter.filterParams = {
              clearButton: true,
              filterOptions: ['equals', 'lessThanOrEqual', 'greaterThanOrEqual'],
              comparator: (filterLocalDateAtMidnight, cellValue) => {
                const dateParts = cellValue.split('-')
                const cellYear = parseInt(dateParts[0], 10)
                const cellMonth = parseInt(dateParts[1], 10) - 1
                const cellDate = new Date(cellYear, cellMonth, 1, 0, 0, 0, 0)
                const filterDate = new Date(filterLocalDateAtMidnight.getFullYear(), filterLocalDateAtMidnight.getMonth(), 1, 0, 0, 0, 0)
                if (cellDate < filterDate) {
                  return -1
                } else if (cellDate > filterDate) {
                  return 1
                }
                return 0
              }
            }
            children.push(lastElementWithDateFilter)
          }
        }
      })
      return ({
        headerName: col,
        children
      })
    })

    columns.unshift({
      field: 'edit',
      headerName: '',
      pinned: 'left',
      width: 90,
      filter: false,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      cellRenderer: params => params.data._id ? '<a href="/settlements/settlement/' + params.data._id + '">Edytuj</a>' : ''
    }
    )

    //
    // modyfikacje kolumn
    //
    const _valuesCols = columns.filter(el => el.headerName === 'Wartości')
    const valuesCols = _valuesCols[0] ? _valuesCols[0].children : []
    const _prepaymentCols = columns.filter(el => el.headerName === 'Przedpłaty')
    const prepaymentCols = _prepaymentCols[0] ? _prepaymentCols[0].children.filter(el => el.field !== 'prepayment_year') : []
    const amountCols = valuesCols.concat(prepaymentCols)

    // klasa css 'amount-cell' i formatowanie tysięcy
    //
    amountCols.map(el => el.cellRenderer = (params) => {
      return `<span class='amount-cell'>${currencyFormatter(params.value)}</span>`
    })
    amountCols.map(el => el.comparator = (num1 = 0, num2 = 0) => num1 - num2)

    amountCols.map(el => el.cellClass = (params) => {
      if ((el.field === 'real_profit' || el.field === 'real_turnover') && params.data._id) {
        return params.value >= 0 ? 'information-cell bg-success' : 'information-cell bg-danger'
      } else {
        return ''
      }
    })

    // edycja kosztu KB tylko dla Finansów
    //
    const costVRCol = valuesCols.find(el => el.field === 'VR_cost')
    if (costVRCol && isFinance) { costVRCol.editable = () => true }

    // klasa css dla edycji i pozycji nierozliczonych
    //
    const editableCols = valuesCols.filter(el => el.editable())

    editableCols.map(el => el.cellClass = (params) => {
      if (!params.data.pinnedBottom) {
        if (isFinance) {
          return 'editable-cell bg-info'
        } else {
          if (params.data.settled !== 'tak') {
            return 'editable-cell bg-info'
          }
        }
      }
    })

    this.setState({ preparedCols: columns, amountCols })
  }

  renderAgGrid () {
    const { searchInput, preparedRows = [], preparedCols = [], amountCols, clearAllFilters } = this.state

    return (
      preparedCols.length && preparedRows.length
        ? <AgGrid
            columns={preparedCols}
            rows={preparedRows}
            searchInput={searchInput}
            setCheckboxesList={this.setCheckboxesList}
            amountCols={amountCols}
            table='settlements'
            clearAllFilters={clearAllFilters}
          />
        : null
    )
  }

  render () {
    const { loader } = this.state
    return (
      <div className='content'>
        <NotificationSystem ref='notificationSystem' style={style} />
        <Loader display={loader} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div className='breadcrumb'>
                    <Link to='/settlements'>Panel rozliczeń</Link> » <Link
                      to='/settlements/list'
                                                                     >Lista rozliczeń
                                                                     </Link>
                  </div>
                }
              />
            </Col>
          </Row>
          <div className={loader ? 'noEvents' : ''}>
            <Row>
              <Col md={12}>
                <nav className='navbar navbar-light bg-light'>
                  <div className='btn-group' style={{ display: 'flex' }}>
                    {this.renderCorrectionButton()}
                    {this.renderSettleButton()}
                    {this.renderFixButton()}
                    {this.renderClearAllFiltersButton()}
                    {this.renderDuplicateButton()}
                    {this.renderTimeRange()}
                    {this.renderSearchInput()}
                    {this.renderSavedFilters()}
                  </div>
                </nav>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card
                  title='Lista Rozliczeń'
                  content={
                    <div>
                      {this.renderAgGrid()}
                    </div>
                  }
                />
              </Col>
            </Row>
          </div>
        </Grid>
        {this.renderModal()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    fakeUser: state.fakeUser,
    main: state.main,
    filters: state.settlements.filters
  }
}

const mapDispatchToProps = {
  setFilters: data => ({
    type: 'SETTLEMENTS_FILTERS',
    filters: data
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(SettlementList)
