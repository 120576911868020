import React, { useRef, useState, useEffect } from 'react'
import { GoogleMap, OverlayViewF, OverlayView, useJsApiLoader } from '@react-google-maps/api'
import { useDispatch, useSelector } from 'react-redux'
import { Glyphicon } from 'react-bootstrap'

import { fetchEmployeesLocations } from '../../redux/actions/employeesLocations'
import './Map.scss'
import mapStyles from './mapStyles.json'
import ClusterPin from '../../assets/img/cluster-pin.png'
import CSPin from '../../assets/img/cs-pin.png'

const containerStyle = { width: '100%', height: '900px' }
const center = { lat: 52.069680, lng: 19.478580 }
const options = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: true,
  styles: mapStyles,
  maxZoom: 12,
  minZoom: 7,
  scrollwheel: false,
  zoomControl: true
}

function MapWithUsers () {
  const dispatch = useDispatch()
  const employeesLocations = useSelector(state => state.employeesLocations || {})
  const { data, loading, error } = employeesLocations
  const { isLoaded: isMapLoaded } = useJsApiLoader({ googleMapsApiKey: process.env.REACT_APP_SC_GOOGLE_MAPS_API_KEY })
  const [zoom, setZoom] = useState(options.minZoom)
  const mapRef = useRef()

  useEffect(() => {
    dispatch(fetchEmployeesLocations())
  }, [dispatch])

  if (error) {
    return <div className='container pt-5'><h2 className='text-center'>Error loading data for map</h2></div>
  }

  if (loading || !data) {
    return <div className='container pt-5'><h2 className='text-center'>Loading...</h2></div>
  }

  if (!isMapLoaded) return null

  function getClusterNames (users) {
    return users.map(({ name }) => name)
  }

  function handleZoomChanged () {
    if (mapRef.current) {
      setZoom(mapRef.current?.zoom)
    }
  }

  function handleMapLoad (map) {
    mapRef.current = map
  }

  return (
    <>
      <h2 style={{ marginTop: '0' }}>O Naszych Cubowiczach!</h2>
      <h4 style={{ marginTop: '0', marginBottom: '10px' }}>Aktualnie aktywnych: {data.usersLength}</h4>
      <h4 style={{ marginTop: '0', marginBottom: '10px' }}>Top 3 miasta: {data.topCities.map(({ city, count }) => `${city} ${count}`).join(', ')}</h4>
      <GoogleMap
        onLoad={handleMapLoad}
        onZoomChanged={handleZoomChanged}
        mapContainerStyle={containerStyle}
        options={options}
        center={center}
        zoom={zoom}
      >
        {Object.keys(data.usersGroupedByCity || {}).map((city, index) => {
          const [lat, lng] = data.usersGroupedByCity[city][0].position

          return data.usersGroupedByCity[city].length > 1
            ? <UserMarker
                key={`cluster-${index}`}
                position={{ lat, lng }}
                names={getClusterNames(data.usersGroupedByCity[city])}
                city={city}
                icon={ClusterPin}
                label={data.usersGroupedByCity[city].length}
              />
            : <UserMarker
                key={`user-${index}`}
                position={{ lat, lng }}
                names={[data.usersGroupedByCity[city][0].name]}
                city={city}
              />
        })}
      </GoogleMap>
      <hr />
    </>
  )
}

function getPixelPositionOffset (width, height) {
  return { x: -(width / 2), y: -(height / 2) }
}

function UserMarker ({ position, names, icon, label, city }) {
  const [visible, setVisible] = useState(false)

  return (
    <OverlayViewF
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getPixelPositionOffset}
    >
      <div className={`overlay-popup ${!label ? 'overlay-popup-single' : ''} ${visible ? 'overlay-popup-is-visible' : ''}`}>
        <div className='overlay-popup-names-holder'>
          <h6>{city}</h6>
          <ol>
            {names.map((name, key) => {
              return (
                <li key={`user-name-${key}`}>{name}</li>
              )
            })}
          </ol>
        </div>
        <button className='overlay-popup-close-btn' onClick={() => setVisible(false)}><Glyphicon glyph='remove' /></button>
      </div>
      <div className={`cluster-ico ${visible ? 'cluster-ico-is-hidden' : ''}`} onClick={() => setVisible(true)}>
        <img src={icon || CSPin} alt='' />
        {label && <span className='cluster-ico-label'>{label}</span>}
      </div>
    </OverlayViewF>
  )
}

export default MapWithUsers
